<template>
  <div>
    <div class="table_search">
      <div class="table_select">
        <div class="search_title">MeSH Term:</div>
        <el-select
          v-model="trait"
          placeholder="select a MeSH Term"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in trait_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="table_select">
        <div class="search_title">Trait Type:</div>
        <el-select
          v-model="traitType"
          placeholder="select a trait type"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="table_select">
        <div class="search_title">Population:</div>
        <el-select
          v-model="population"
          placeholder="select a population"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in population_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="search_button">
        <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
    </div>
    <div>
      <div class="table_container">
        <el-table 
          v-loading="loading"
          :data="pagedData"
          :border="true"
          style="width: 100%"
          @sort-change="sortChange"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
        >
          <el-table-column sortable prop="FileID" label="Study" />
          <el-table-column prop="Trait" label="Trait" width="300" />
          <el-table-column prop="MeshTerm" label="MeSH Term" :formatter="NAFormat" />
          <el-table-column prop="TraitType" label="Trait Type" />
          <el-table-column sortable prop="Ncontrol" label="NControl" :formatter="NAFormat" />
          <el-table-column sortable prop="Ncase" label="NCase" :formatter="NAFormat" />
          <el-table-column sortable prop="SampleSize" label="Sample Size" :formatter="NAFormat" />
          <el-table-column prop="Population" label="Population" />
          <el-table-column prop="FirstAuthor" label="Author" />
          <el-table-column sortable prop="Year" label="Year" /> 
          <el-table-column sortable prop="PMID" label="PMID" :formatter="NAFormat" >
            <template v-slot="scope">
              <a v-if="scope.row.PMID != 'NA'" class="pmid" :href="'https://pubmed.ncbi.nlm.nih.gov/'+scope.row.PMID" target="_blank">{{scope.row.PMID}}</a>
            </template>
          </el-table-column>
          <el-table-column prop="methods" label="Method" width="300px">
            <template #default="{ row }">
              <router-link
                class="table_method"
                v-for="method in row.methods"
                :key="method.name"
                :to="{
                  name:method.router,
                  params:{
                    study:row.FileID
                  }
                }"
                target="_blank"
                >
                {{ method.name }}
              </router-link>
            </template>
          </el-table-column>
        </el-table>  
        
        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="Total"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch, getCurrentInstance  } from 'vue'
import { ElTable, ElTableColumn, ElSelect, ElOption, ElButton, ElPagination } from 'element-plus'
import { useRoute } from 'vue-router';
export default {
  name:'Overview',
  components:{
    ElTable,
    ElTableColumn,
    ElOption,
    ElSelect,
    ElButton,
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    var loading = ref(false)

    var table_data = ref([])
    var filter_table_data = ref([])
    
    var trait = ref('')
    var trait_options = ref([])
    var traitType = ref('')
    var type_options = reactive([
      {
        value:'binary',
        label:'binary'
      },
      {
        value:'quantitative',
        label:'quantitative'
      }
    ])
    var population = ref('')
    var population_options = reactive([
      {
        value:'AFR',
        label:'AFR'
      },
      {
        value:'AMR',
        label:'AMR'
      },
      {
        value:'EAS',
        label:'EAS'
      },
      {
        value:'EUR',
        label:'EUR'
      },
      
      {
        value:'MIX',
        label:'MIX'
      },
      {
        value:'SAS',
        label:'SAS'
      },
    ])
    var currentPage = ref(1)
    var pageSize = ref(10)
    var Total = ref(0)

    const sortChange = (arg) => {
      let sortData = filter_table_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'FileID' || arg.prop === 'PMID'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'FileID' || arg.prop === 'PMID'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filter_table_data.value = sortData
      currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    var filterByTraitOrAuthor = () => {
      filter_table_data.value = table_data.value.filter(
        (data) => {
          return (!trait.value || data.MeshTerm.toLowerCase() === trait.value.toLowerCase()) 
          &&
          (!traitType.value || data.TraitType.toLowerCase().includes(traitType.value.toLowerCase()))
          &&
          (!population.value || data.Population.toLowerCase().includes(population.value.toLowerCase()))
        }
      )
      Total.value = filter_table_data.value.length
    }

    const fetchtabledata = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/overview');
        table_data.value = response.data.map(trait => {
          return {
            ...trait,
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
      loading.value = false
    }

    const writeOption = () => {
      let traitSet = new Set(table_data.value.map(item => item.MeshTerm))
      trait_options.value = Array.from(traitSet).sort().map(item => ({
        label:item,
        value:item
      }))
    }

    var reset = () => {
      trait.value = ''
      traitType.value = ''
      population.value = ''
      filter_table_data.value = table_data.value
      Total.value = filter_table_data.value.length
    }

    const pagedData = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize.value;
      const endIndex = startIndex + pageSize.value;
      return filter_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      currentPage.value = 1
    };

    const NAFormat = (row,column,value) => {
      return value == ("NA" || Infinity) ? '-' : value
    }

    onMounted(() => {
      fetchtabledata()
      if(route.query.Trait){
        trait.value = route.query.Trait
      }

    })
    
    watch(table_data, (newValue, oldValue) => {
      writeOption()
      filterByTraitOrAuthor()
    })

    return {
      loading,
      filter_table_data,
      trait,
      trait_options,
      traitType,
      type_options,
      population,
      population_options,
      writeOption,
      filterByTraitOrAuthor,
      reset,
      currentPage,
      pageSize,
      Total,
      pagedData,
      handleCurrentChange,
      handleSizeChange,
      NAFormat,
      sortChange
    }
  }
}
</script>

<style scoped>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .table_container{
    padding: 0 24px 24px 24px;
    overflow-x: auto;
  }
  .table_search{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
  }
  .search_title{
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .search_button{
    flex: right;
  }
  .table_select{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .table_method{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    margin: .5ch;
    border: 1px solid rgb(189, 189, 189);
    padding: 0 10px;
    border-radius: 16px;
    height: 24px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color, 0.3s ease-in-out;
  }
  .table_method:hover{
    background-color: rgb(235, 235, 235);
  }
</style>