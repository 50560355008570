<template>
  <div style="display: flex;">
    <div class="anchor">
      <el-affix>
        <el-anchor size="large" ref="containerRef" @click="handleClick">
          <el-anchor-link href="#overview" title="overview">Overview</el-anchor-link>
          <el-anchor-link href="#method" title="method">Methods Used by CardioGene</el-anchor-link>
          <el-anchor-link href="#explanation" title="explanation">How to use CardioGene</el-anchor-link>
          <el-anchor-link href="#citations" title="citations">Citations</el-anchor-link>
          <el-anchor-link href="#contact" title="contact">Contact</el-anchor-link>
        </el-anchor>
      </el-affix>
      <!-- <ul>
        <li><a href="#overview" rel="external nofollow" @click="scrollToAnchor">Overview</a></li>
        <li><a href="#method" rel="external nofollow" @click="scrollToAnchor">Methods Used by CardioGene</a></li>
        <li><a href="#explanation" rel="external nofollow" @click="scrollToAnchor">How to use CardioGene</a></li>
        <li><a href="#citations" rel="external nofollow" @click="scrollToAnchor">Citations</a></li>
        <li><a href="#contact" rel="external nofollow" @click="scrollToAnchor">Contact</a></li>
      </ul> -->
      <!-- <ul>
        <li><router-link to="#method">1</router-link></li>
      </ul> -->
    </div>
    <div class="about">
      <div id="overview">
        <h2>Overview</h2>
        <p>Cardiovascular diseases are a major cause of death globally, estimated to claim 17.9 million lives each year, significantly impacting human health, quality of life, and healthcare costs. Understanding the complex genetic mechanisms of cardiovascular diseases is crucial for developing targeted therapies, improving prevention strategies, and ultimately reducing the global burden of these life-threatening conditions. CardioGene is a platform dedicated to understanding the genetic basis of cardiovascular related traits. Despite the widespread use of genetic tools in studying complex diseases, significant challenges remain in interpreting these mechanisms. Many loci identified through GWAS are located in non-coding regions, complicating their interpretation. Non-coding regulatory elements such as transcription factors, alternative splicing, methylation, and acetylation can influence TWAS results, making it difficult to identify causal genes. CardioGene addresses these challenges by offering comprehensive genetic analysis through multiple methods to identify potential causal genes, variants, and relevant tissues or cell types for cardiovascular related traits. The platform integrates 1518 GWAS summary statistics, covering a variety of conditions and indicators, including: Diseases: Such as coronary artery disease, atherosclerosis. Biochemical Indicators: Such as lipoprotein cholesterol levels, apolipoprotein levels. Physiological Measurements: Such as pulse rate, systolic blood pressure. Drugs: Such as pravastatin, nicorandil. Through its extensive database and thorough analysis tools, CardioGene aims to provide deeper insights into the genetic factors underlying cardiovascular related traits.</p>
      </div>
      <div id="method">
        <h2>Methods Used by CardioGene</h2>
        <p>CardioGene employs 12 methods to analyze cardiovascular diseases and related phenotypes GWAS summary statistics:</p>
        <div><span><p>MAGMA:</p></span><p><a class="method" href="https://ctg.cncr.nl/software/magma" target="_blank">MAGMA</a> Used for gene and gene-set analysis, MAGMA identifies associations between genetic variants and phenotypes by mapping SNPs to genes and testing for enrichment of GWAS signals in predefined gene sets.</p></div>
        <div><span><p>GARFIELD:</p></span><p><a class="method" href="https://www.ebi.ac.uk/birney-srv/GARFIELD/" target="_blank">GARFIELD</a> Integrates GWAS summary statistics with regulatory or functional annotations from databases like ENCODE and Roadmap Epigenomics to identify phenotype-relevant features, including genic annotations, chromatin states, histone modifications, DNaseI hypersensitive sites, and transcription factor binding sites.</p></div>
        <div><span><p>LDAK and LDSC:</p></span><p> These methods compute SNP-based heritability and genetic correlations between phenotypes. <a class="method" href="https://dougspeed.com/ldak/" target="_blank">LDAK</a> also estimates unique and total heritability across multiple components, while <a class="method" href="https://github.com/bulik/ldsc" target="_blank">LDSC</a> quantifies the proportion of phenotypic variance explained by the SNPs.</p></div>
        <div><span><p>S-PrediXcan, UTMOST, and JTI:</p></span><p> These three TWAS methods identify potential phenotype-associated genes by integrating GWAS summary statistics with gene expression data:</p>
          <div><p><a class="method" href="https://github.com/hakyimlab/MetaXcan" target="_blank">S-PrediXcan</a>: Extends PrediXcan by using GWAS summary statistics to estimate the component of gene expression influenced by an individual's genetic profile and identifying associated genes using TWAS.</p></div>
          <div><p><a class="method" href="https://github.com/Joker-Jerome/UTMOST" target="_blank">UTMOST</a>: A cross-tissue TWAS method that leverages shared genetic regulation across tissues.</p></div>
          <div><p><a class="method" href="https://github.com/gamazonlab/MR-JTI" target="_blank">JTI</a>: Improves predictive performance through joint tissue imputation, using shared genetic regulation across multiple tissues.</p></div>
          </div> 
        <div><span><p>SMR (Summary-based Mendelian Randomization):</p></span><p><a class="method" href="https://yanglab.westlake.edu.cn/software/smr/#Overview" target="_blank">SMR</a> Tests the pleiotropic association between gene expression levels and complex traits using GWAS summary statistics and eQTL (expression Quantitative Trait Loci) studies to prioritize genes implicated by GWAS hits.</p></div>
        <div><span><p>COLOC:</p></span><p><a class="method" href="http://chr1swallace.github.io/coloc/index.html" target="_blank">COLOC</a> Assesses genetic colocalization to investigate shared genetic variants in specific regions between two potentially related phenotypes.</p></div>
        <div><span><p>ABF, FINEMAP, and SuSiE:</p></span><p> These three fine mapping methods infer causal variations: </p>
          <div><p><a class="method" href="https://chr1swallace.github.io/coloc/reference/finemap.abf.html" target="_blank">ABF (Approximate Bayes Factor)</a>: Uses Bayesian models to identify causal variants.</p></div>
          <div><p><a class="method" href="http://www.christianbenner.com/" target="_blank">FINEMAP</a>: Utilizes shotgun stochastic search algorithms for fine mapping of genetic variants.</p></div>
          <div><p><a class="method" href="https://stephenslab.github.io/susieR/" target="_blank">SuSiE (Sum of Single Effects)</a>: Applies sparse multiple regression models to identify likely causal variants. CardioGene's integration of these diverse methods provides a comprehensive understanding of the genetic basis of cardiovascular diseases, aiding in the identification of potential causal genes and associated biological pathways.</p></div>
        </div>
      </div>
      <div id="explanation">
          <h2 >How to use CardioGene</h2>
          <h4>Home page</h4>
          <div>
            <p>The home page briefly introduces the data and methods used by CardioGene, and users can select a trait to search for.</p>
            <img src="../assets/home.png" alt="home">
          </div>
          <h4>Overview page</h4>
          <div>
            <p>The overview page includes basic information about all the trait data. The user can filter table through the selection box above. Click a method in the method column to jump to the method page corresponding to the trait and view specific results.</p>
            <img src="../assets/overview.png" alt="overview">
          </div>
          <h4>Heritability page</h4>
          <div>
            <p>In Heritability page, the bar plot displays heritability and standard error computed by LDAK and LDSC of the trait. The table shows all the results.</p>
            <img src="../assets/h2.png" alt="h2">
            <p>Next is the heritability of different gene components estimated by LDAK. The user can filter the plot content by dragging the scale up and down on the right of the plot.</p>
            <img src="../assets/ldak_h2.png" alt="ldak_h2">
            <p>The last part is the genetic correlation between traits form LDAK and LDSC. The nodes in the plot represent traits, the color of the line represents the positive or negative genetic correlation (red is positive and blue is negative), and the thickness of the line represents the absolute value of the genetic correlation.</p>
            <img src="../assets/gc.png" alt="gc">
          </div>
          <h4>SMR page and COLOC page</h4>
          <div>
            <p>In SMR page, the heat map shows the SMR results of this trait in different tissues and various genes. The user can filter the plot content by dragging the scale up and down on the right of the plot or by selecting the selection box above the table. Same for the COLOC page.</p>
            <img src="../assets/smr.png" alt="smr">
          </div>
          <h4>Fine mapping page</h4>
          <div>
            <p>In the fine mapping page, the plot presents the results computed by ABF , FINEMAP and SuSiE for each significant locus of each trait study. The user can input and select a locus to view results under three zooms.</p>
            <img src="../assets/finemap.png" alt="finemap">
          </div>
        </div>
      <div id="citations">
        <h2>Citations</h2>
        <div><p>MAGMA：de Leeuw, C. A., Mooij, J. M., Heskes, T., & Posthuma, D. (2015). MAGMA: generalized gene-set analysis of GWAS data. PLoS computational biology, 11(4), e1004219. https://doi.org/10.1371/journal.pcbi.1004219</p></div>
        <div><p>GARFIELD: Iotchkova, V., Ritchie, G. R. S., Geihs, M., Morganella, S., Min, J. L., Walter, K., Timpson, N. J., UK10K Consortium, Dunham, I., Birney, E., & Soranzo, N. (2019). GARFIELD classifies disease-relevant genomic features through integration of functional annotations with association signals. Nature genetics, 51(2), 343–353. https://doi.org/10.1038/s41588-018-0322-6</p></div>
        <div><p>LDAK：Berrandou, T. E., Balding, D., & Speed, D. (2023). LDAK-GBAT: Fast and powerful gene-based association testing using summary statistics. American journal of human genetics, 110(1), 23–29. https://doi.org/10.1016/j.ajhg.2022.11.010</p></div>
        <div><p>LDSC: Bulik-Sullivan, B. K., Loh, P. R., Finucane, H. K., Ripke, S., Yang, J., Schizophrenia Working Group of the Psychiatric Genomics Consortium, Patterson, N., Daly, M. J., Price, A. L., & Neale, B. M. (2015). LD Score regression distinguishes confounding from polygenicity in genome-wide association studies. Nature genetics, 47(3), 291–295. https://doi.org/10.1038/ng.3211</p></div>
        <div><p>SPrediXcan: Barbeira, A. N., Dickinson, S. P., Bonazzola, R., Zheng, J., Wheeler, H. E., Torres, J. M., Torstenson, E. S., Shah, K. P., Garcia, T., Edwards, T. L., Stahl, E. A., Huckins, L. M., GTEx Consortium, Nicolae, D. L., Cox, N. J., & Im, H. K. (2018). Exploring the phenotypic consequences of tissue specific gene expression variation inferred from GWAS summary statistics. Nature communications, 9(1), 1825. https://doi.org/10.1038/s41467-018-03621-1</p></div>
        <div><p>UTMOST: Hu, Y., Li, M., Lu, Q., Weng, H., Wang, J., Zekavat, S. M., Yu, Z., Li, B., Gu, J., Muchnik, S., Shi, Y., Kunkle, B. W., Mukherjee, S., Natarajan, P., Naj, A., Kuzma, A., Zhao, Y., Crane, P. K., Alzheimer’s Disease Genetics Consortium,, Lu, H., … Zhao, H. (2019). A statistical framework for cross-tissue transcriptome-wide association analysis. Nature genetics, 51(3), 568–576. https://doi.org/10.1038/s41588-019-0345-7</p></div>
        <div><p>JTI: Zhou, D., Jiang, Y., Zhong, X., Cox, N. J., Liu, C., & Gamazon, E. R. (2020). A unified framework for joint-tissue transcriptome-wide association and Mendelian randomization analysis. Nature genetics, 52(11), 1239–1246. https://doi.org/10.1038/s41588-020-0706-2</p></div>
        <div><p>SMR：Zhu, Z., Zhang, F., Hu, H., Bakshi, A., Robinson, M. R., Powell, J. E., Montgomery, G. W., Goddard, M. E., Wray, N. R., Visscher, P. M., & Yang, J. (2016). Integration of summary data from GWAS and eQTL studies predicts complex trait gene targets. Nature genetics, 48(5), 481–487. https://doi.org/10.1038/ng.3538</p></div>
        <div><p>COLOC: Giambartolomei, C., Vukcevic, D., Schadt, E. E., Franke, L., Hingorani, A. D., Wallace, C., & Plagnol, V. (2014). Bayesian test for colocalisation between pairs of genetic association studies using summary statistics. PLoS genetics, 10(5), e1004383. https://doi.org/10.1371/journal.pgen.1004383</p></div>
        <div><p>ABF: Wakefield J. (2009). Bayes factors for genome-wide association studies: comparison with P-values. Genetic epidemiology, 33(1), 79–86. https://doi.org/10.1002/gepi.20359</p></div>
        <div><p>FINEMAP: Hans, C., Dobra, A., & West, M. (2007). Shotgun stochastic search for “large p” regression. Journal of the American Statistical Association, 102(478), 507-516.</p></div>
        <div><p>Susie: Wang, G., Sarkar, A., Carbonetto, P., & Stephens, M. (2020). A simple new approach to variable selection in regression, with application to genetic fine mapping. Journal of the Royal Statistical Society. Series B, Statistical methodology, 82(5), 1273–1300. https://doi.org/10.1111/rssb.12388</p></div>
      </div>
      <div id="contact">
        <h2>Contact</h2>
        <div><p>To contact our team, with queries, comments or feedback please email caochen@njmu.edu.cn, guning@nju.edu.cn.</p></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElAnchor, ElAnchorLink, ElAffix } from 'element-plus';
import { useRouter } from 'vue-router';
import {ref} from 'vue'

export default {
  name:'Documentation',
  components: {
    ElAnchor,
    ElAnchorLink,
    ElAffix
  },
  setup(){
    const containerRef = ref<HTMLElement | null>(null)
    function handleClick(e) {
      e.preventDefault()
    }
    // function scrollToAnchor(e){
    //   const href = e.target.getAttribute('href');
    //   window.location.hash = href
    // }
    //   const router = useRouter()

      // router.beforeEach((to, from) => {
      //   console.log(to)
      //   if (to.hash) {
      //     console.log(to.hash)
      //     const element = document.querySelector(to.hash);
          
      //       element.scrollIntoView({behavior:'smooth'})
      //       console.log(1)
          
      //   }
      // })

    return {
      containerRef,
      handleClick
    }
  }
}
</script>

<style scoped>
  .anchor{
    float: left;
    width: 25%;
  }
  .about{
    float: right;
    font-size: 20px;
    line-height: 1.7;
    width: 75%;
  }
  span{
    font-weight: bold;
  }
  .div2{
    margin-left: 25px;
  }
  .method{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>