<template>
  <h2 class="title">
    The MAGMA results for {{ trait }}
  </h2>
  <div class="figure">
    <img v-if="manhattan" :src="manhattan" alt="manhattan">
    <img v-if="qq" :src="qq" alt="qq">
  </div>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">Gene:</div>
      <div>
        <el-select-v2
          v-model="gene"
          placeholder="input and select a gene"
          filterable
          :options="gene_options"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="search_button">
        <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
  </div>
  <div>
    <div  class="table_container">
      <el-table 
        v-loading="loading"
        :data="pagedData"
        :border="true"
        style="width: 100%"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="STUDY" label="Study" />
        <el-table-column prop="TRAIT" label="Trait" />
        <el-table-column sortable prop="GENE" label="Gene Symbol">
          <template v-slot="scope">
            <a class="pmid" :href="'https://www.ncbi.nlm.nih.gov/gene/'+scope.row.GENEID" target="_blank">{{scope.row.GENE}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="GENEID" label="Gene ID" >
          <template v-slot="scope">
            <a class="pmid" :href="'https://www.ncbi.nlm.nih.gov/gene/'+scope.row.GENEID" target="_blank">{{scope.row.GENEID}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="P" label="P" :formatter="NAFormat" />
        <el-table-column sortable prop="N" label="#Sample" :formatter="NAFormat" />
        <el-table-column sortable prop="NSNPS" label="#SNP" :formatter="NAFormat" />
        <el-table-column sortable prop="CHR" label="Chromosome" :formatter="NAFormat" /> 
        <el-table-column sortable prop="START" label="Start" :formatter="NAFormat" />
        <el-table-column sortable prop="STOP" label="Stop" :formatter="NAFormat" />
      </el-table>

      <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="Total"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted, computed, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
import { ElSelect, ElOption, ElTable, ElTableColumn, ElButton, ElDivider, ElPagination, ElSelectV2 } from 'element-plus';
export default {
  name:'MAGMA',
  components:{
    ElSelect,
    ElOption,
    ElTable,
    ElTableColumn,
    ElButton,
    ElDivider,
    ElPagination,
    ElSelectV2
  },
  setup(){
    const { proxy } = getCurrentInstance()
    var loading = ref(false)
    const route = useRoute()
    var trait = ref(route.params.study)
    var manhattan = ref('')
    var qq = ref('')
    var table_data = ref([])

    var filter_table_data = ref(table_data.value)
    var gene=ref('')
    var gene_options = ref([])

    var filterByTraitOrAuthor = () => {
      filter_table_data.value = table_data.value.filter(
        (data) => {
          return (!gene.value || data.GENE.toLowerCase().includes(gene.value.toLowerCase())) 
        }
      )
      Total.value = filter_table_data.value.length
      currentPage.value = 1
    }

    var reset = () => {
      gene.value = ''
      filter_table_data.value = table_data.value
      Total.value = filter_table_data.value.length
      currentPage.value = 1
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    const sortChange = (arg) => {
      let sortData = filter_table_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'GENE'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'GENE'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filter_table_data.value = sortData
      currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    const pagedData = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize.value;
      const endIndex = startIndex + pageSize.value;
      return filter_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    const fetchtabledata = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/magma',{ 
          params:{
            study:route.params.study
        }})
        table_data.value = response.data
        loading.value = false
      } catch (error) {
        console.log('fail', error.message)
      }
      loading.value = false
    }

    const fetchGeneListdata = async () => {
      try {
        const response = await proxy.$request.get('/magma_gene',{ 
          params:{
            study:route.params.study
        }})
        gene_options.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchPNG = async () => {
      try {
        const response = await proxy.$request.get('/magmaPNG',{ 
          params:{
            study:route.params.study
        }})
        manhattan.value = response.data[0]
        qq.value = response.data[1]
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    watch(table_data, (newValue, oldValue) => {
      filterByTraitOrAuthor()
    })
    
    onMounted(() => {
      fetchtabledata()
      fetchGeneListdata()
      fetchPNG()
    })
    
    return {
      loading,
      route,
      trait,
      manhattan,
      qq,
      table_data,
      filter_table_data,
      pagedData,
      currentPage,
      pageSize,
      Total,
      gene,
      gene_options,
      filterByTraitOrAuthor,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      NAFormat
    }
  }
}
</script>
<style scoped>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .title{
    font-weight: 600;
    font-size: 30px;
    line-height: 1.35;
    font-family: "Trebuchet MS", Arial, sans-serif;
    text-align: center;
  }
  .figure{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 75%;
  }
  .table_container{
    padding: 0 24px 24px 24px;
    overflow-x: auto;
  }
  table{
    border-collapse: collapse;
    border-bottom: 1px solid rgba(0, 0, 0, .06);
    border-left: 1px solid rgba(0, 0, 0, .06);
    width: 100%;
    font-family: "Trebuchet MS", Arial, sans-serif;;
    font-size: 14px;
    text-align: left;
    line-height: 1.5;
  }
  tr{
    border-top: 1px solid rgba(0, 0, 0, .06);
  }
  th, td{
    border-right: 1px solid rgba(0, 0, 0, .06);
    padding: 12px 8px;
  }
  th{
    background-color: rgba(0, 0, 0, .02);
    font-family: sans-serif;
  }
  .table_search{
    display: flex;
    margin-bottom: 30px;
  }
  .table_select{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .search_title{
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
</style>