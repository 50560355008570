import {createRouter, createWebHashHistory} from 'vue-router'

import Home from '../pages/Home';
import Overview from '../pages/Overview'
import Search from '../pages/Search'
import Download from '../pages/Download'
import Documentation from '../pages/Documentation'
import MAGMA from '@/pages/MAGMA.vue';
import GARFIELD from '@/pages/GARFIELD.vue';
import Heritability from '@/pages/Heritability.vue';
import TWAS from '@/pages/TWAS.vue';
import SMR from '@/pages/SMR.vue';
import COLOC from '@/pages/COLOC.vue';
import FineMapping from '@/pages/FineMapping.vue';

export default createRouter({
  history:createWebHashHistory(),
  routes:[
    {
      path:'/',
      component:Home
    },
    {
      name:'Home',
      path:'/Home',
      component:Home
    },
    {
      name:'Overview',
      path:'/Overview',
      component:Overview
    },
    {
      name:'Search',
      path:'/Search',
      component:Search
    },
    {
      name:'Download',
      path:'/Download',
      component:Download
    },
    {
      name:'Documentation',
      path:'/Documentation',
      component:Documentation
    },
    {
      name:'MAGMA',
      path:'/MAGMA/:study',
      component:MAGMA
    },
    {
      name:'GARFIELD',
      path:'/GARFIELD/:study',
      component:GARFIELD
    },
    {
      name:'Heritability',
      path:'/Heritability/:study',
      component:Heritability
    },
    {
      name:'TWAS',
      path:'/TWAS/:study',
      component:TWAS
    },
    {
      name:'SMR',
      path:'/SMR/:study',
      component:SMR
    },
    {
      name:'COLOC',
      path:'/COLOC/:study',
      component:COLOC
    },
    {
      name:'FineMapping',
      path:'/FineMapping/:study',
      component:FineMapping
    },
  ]
})