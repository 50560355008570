<template>
  <h2 style="text-align: center;font-size: 32px;">The SMR results for {{ trait }}</h2>
  <div v-loading="Eloading" v-show="showEqtl" class="SMReqtl">
    <el-divider />
    <h2 style="text-align: center;font-size: 32px;">Genes mediating {{ trait }} by SMR analysis on eQTL</h2>
    <div ref="SMReqtlChart" class="bar"></div>
    <div>
      <div class="table_search">
        <div class="table_select">
          <div class="search_title">Tissue:</div>
          <div>
            <el-select
              v-model="eqtl_tissue"
              placeholder="input and select a tissue"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtl_tissue_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">Gene:</div>
          <div>
            <el-select
              v-model="eqtl_gene"
              placeholder="input and select a gene"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtl_gene_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">Top SNP:</div>
          <div>
            <el-select
              v-model="eqtl_top_SNP"
              placeholder="input and select a top SNP"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtl_top_SNP_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="search_button">
            <el-button type="primary" @click="filter_eqtl">Search</el-button>
            <el-button @click="reset_eqtl">Reset</el-button>
          </div>
      </div>
      <div class="table_container">
        <el-table 
          :data="eqtl_pagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortEqtlChange"
        >
          <el-table-column prop="Study" label="Study" min-width="150" />
          <el-table-column prop="Trait" label="Trait" min-width="150" />
          <el-table-column prop="Tissue" label="Tissue" min-width="150" />
          <el-table-column sortable prop="Gene" label="Gene" min-width="150">
            <template v-slot="scope">
              <a v-if="scope.row.Gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.Gene" target="_blank">{{scope.row.Gene}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="topSNP" label="Top SNP" min-width="150">
            <template v-slot="scope">
              <a v-if="scope.row.topSNP" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.topSNP" target="_blank">{{scope.row.topSNP}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="topSNP_chr" label="Top SNP Chr" min-width="150" />
          <el-table-column sortable prop="topSNP_bp" label="Top SNP bp" min-width="150" />
          <el-table-column sortable prop="A1" label="A1" min-width="150" />
          <el-table-column sortable prop="A2" label="A2" min-width="150" />
          <el-table-column sortable prop="Freq" label="Frequency" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="b_GWAS" label="GWAS Beta" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="se_GWAS" label="GWAS Beta SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="p_GWAS" label="GWAS P Value" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="b_eQTL" label="eQTL Beta" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="se_eQTL" label="eQTL Beta SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="p_eQTL" label="eQTL P Value" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="b_SMR" label="SMR Beta" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="se_SMR" label="SMR Beta SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="p_SMR" label="SMR P Value" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="FDR" label="SMR FDR" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="p_HEIDI" label="HEIDI P Value" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="nsnp_HEIDI" label="HEIDI SNP" min-width="150" :formatter="NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handle_eqtl_SizeChange"
          @current-change="handle_eqtl_CurrentChange"
          :current-page="eqtl_currentPage"
          :total="eqtl_Total"
          :page-sizes="[5,10,20]"
          :page-size="eqtl_pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
      </div>
    </div>
  </div>
  
  <div v-loading="Sloading" v-show="showSqtl" class="SMRsqtl">
    <el-divider />
    <h2 style="text-align: center;font-size: 32px;">Genes mediating {{ trait }} by SMR analysis on sQTL</h2>
    <div ref="SMRsqtlChart" class="bar"></div>
    <div>
      <div class="table_search">
      <div class="table_select">
        <div class="search_title">Tissue:</div>
        <div>
          <el-select
            v-model="sqtl_tissue"
            placeholder="input and select a tissue"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in sqtl_tissue_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="table_select">
        <div class="search_title">Gene:</div>
        <div>
          <el-select
            v-model="sqtl_gene"
            placeholder="input and select a gene"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in sqtl_gene_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="table_select">
        <div class="search_title">Top SNP:</div>
        <div>
          <el-select
            v-model="sqtl_top_SNP"
            placeholder="input and select a top SNP"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in sqtl_top_SNP_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="search_button">
          <el-button type="primary" @click="filter_sqtl">Search</el-button>
          <el-button @click="reset_sqtl">Reset</el-button>
        </div>
    </div>
      <div class="table_container">
        <el-table 
          :data="sqtl_pagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortSqtlChange"
        >
        <el-table-column prop="Study" label="Study" min-width="150" />
        <el-table-column prop="Trait" label="Trait" min-width="150" />
        <el-table-column prop="Tissue" label="Tissue" min-width="150" />
        <el-table-column sortable prop="Gene" label="Gene" min-width="150">
          <template v-slot="scope">
            <a v-if="scope.row.Gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.Gene" target="_blank">{{scope.row.Gene}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="topSNP" label="Top SNP" min-width="150">
          <template v-slot="scope">
            <a v-if="scope.row.topSNP" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.topSNP" target="_blank">{{scope.row.topSNP}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="topSNP_chr" label="Top SNP chr" min-width="150" />
        <el-table-column sortable prop="topSNP_bp" label="Top SNP bp" min-width="150" />
        <el-table-column sortable prop="A1" label="A1" min-width="150" />
        <el-table-column sortable prop="A2" label="A2" min-width="150" />
        <el-table-column sortable prop="Freq" label="Frequency" min-width="150" :formatter="NAFormat" />
        <el-table-column sortable prop="b_GWAS" label="GWAS Beta" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="se_GWAS" label="GWAS Beta SE" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="p_GWAS" label="GWAS P Value" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="b_eQTL" label="eQTL Beta" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="se_eQTL" label="eQTL Beta SE" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="p_eQTL" label="eQTL P Value" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="b_SMR" label="SMR Beta" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="se_SMR" label="SMR Beta SE" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="p_SMR" label="SMR P Value" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="FDR" label="SMR FDR" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="p_HEIDI" label="HEIDI P Value" min-width="150" :formatter="NAFormat" /> 
        <el-table-column sortable prop="nsnp_HEIDI" label="HEIDI SNP" min-width="150" :formatter="NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handle_sqtl_SizeChange"
          @current-change="handle_sqtl_CurrentChange"
          :current-page="sqtl_currentPage"
          :total="sqtl_Total"
          :page-sizes="[5,10,20]"
          :page-size="sqtl_pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ElDivider, ElTable, ElTableColumn, ElSelect, ElOption, ElButton, ElInput, ElPagination } from 'element-plus';
import * as echarts from 'echarts'
import { ref, onMounted,computed, watch, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';

export default {
  name:'SMR',
  components: {
    ElDivider,
    ElTable, 
    ElTableColumn,
    ElSelect, 
    ElOption, 
    ElButton, 
    ElInput, 
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const trait = ref(route.params.study)
    const SMReqtlChart = ref(null)
    const SMRsqtlChart = ref(null)
    var eqtl_data = ref([])
    var eqtl_fig_data = ref(null)
    var showEqtl = ref(true)
    var showSqtl = ref(true)
    var Eloading = ref(false)
    var Sloading = ref(false)

    var eqtl_gene_list = ref([])
    // prettier-ignore
    var eqtl_tissue_list = ref([])

    var SMReqtlChartInit = () => {
      let Chart = echarts.init(SMReqtlChart.value);
      let gridSize = [Chart.getWidth()/eqtl_gene_list.value.length, Chart.getHeight()/eqtl_tissue_list.value.length]
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Tissue: ' +
              eqtl_tissue_list.value[params.value[1]] +
              '<br>Gene: ' +
              eqtl_gene_list.value[params.value[0]] +
              '<br>-log10(P-value of SMR): ' +
              params.value[3].toFixed(4)
            );
          }
        },
        grid: {
          containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: eqtl_gene_list.value,
          name:'Gene',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -90
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: eqtl_tissue_list.value,
          name:'Tissue',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'SMR_eQTL',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return [Chart.getWidth()/eqtl_gene_list.value.length/2, Chart.getHeight()/eqtl_tissue_list.value.length/2]
            },
            data: eqtl_fig_data.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0,
          max: MaxEP.value,
          dimension: 3,
          right: 0,
          precision:0,
          top: 'center',
          text: ['', '-log10(P)'],
          calculable: true,
          inRange: {
            color: ['#0055ff', '#ff0004']
          }
        },
      };
      Chart.setOption(options);
    }

    var filter_eqtl_data = ref([])
    var eqtl_tissue = ref('')
    var eqtl_tissue_options = ref([])
    var eqtl_gene = ref('')
    var eqtl_gene_options = ref([])
    var eqtl_top_SNP = ref('')
    var eqtl_top_SNP_options = ref([])
    var MaxEP = ref(null)
    var MaxSP = ref(null)

    var filter_eqtl = () => {
      if (eqtl_data.value.length == 0){
        return
      }
      filter_eqtl_data.value = eqtl_data.value.filter(
        (data) => {
          return (!eqtl_tissue.value || data.Tissue == eqtl_tissue.value) &&
          (!eqtl_gene.value || data.Gene == eqtl_gene.value) &&
          (!eqtl_top_SNP.value || data.topSNP == eqtl_top_SNP.value)
        }
      )
      eqtl_Total.value = filter_eqtl_data.value.length
      eqtl_currentPage.value = 1
    }

    var reset_eqtl = () => {
      eqtl_tissue.value = ''
      eqtl_gene.value = ''
      eqtl_top_SNP.value = ''
      filter_eqtl_data.value = eqtl_data.value
      eqtl_Total.value = filter_eqtl_data.value.length
      eqtl_currentPage.value = 1
    }

    var eqtl_Total = ref(0)
    var eqtl_currentPage = ref(1)
    var eqtl_pageSize = ref(5)

    const eqtl_pagedData = computed(() => {
      const startIndex = (eqtl_currentPage.value - 1) * eqtl_pageSize.value;
      const endIndex = startIndex + eqtl_pageSize.value;
      return filter_eqtl_data.value.slice(startIndex, endIndex);
    });

    const sortEqtlChange = (arg) => {
      let sortData = filter_eqtl_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'Gene' || arg.prop === 'topSNP' || arg.prop === 'A1' || arg.prop === 'A2'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'Gene' || arg.prop === 'topSNP' || arg.prop === 'A1' || arg.prop === 'A2'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filter_eqtl_data.value = sortData
      eqtl_currentPage.value = 1
    }

    const sortSqtlChange = (arg) => {
      let sortData = filter_sqtl_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'Gene' || arg.prop === 'topSNP' || arg.prop === 'A1' || arg.prop === 'A2'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'Gene' || arg.prop === 'topSNP' || arg.prop === 'A1' || arg.prop === 'A2'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filter_sqtl_data.value = sortData
      sqtl_currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }
    
    // 处理页码改变事件
    const handle_eqtl_CurrentChange = (newPage) => {
      eqtl_currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handle_eqtl_SizeChange = (newSize) => {
      eqtl_pageSize.value = newSize;
      eqtl_currentPage.value = 1
    };

    const fetcheQTLData = async () => {
      Eloading.value = true
      try {
        const response = await proxy.$request.get('/smr_eQTL',{ 
          params:{
            study:route.params.study
        }})
        eqtl_data.value = response.data
        if (eqtl_data.value.length == 0){
          showEqtl.value = false
        }
      } catch (error) {
        console.log('fail', error.message)
      }
      Eloading.value = false
    }

    var sqtl_data = ref([])
    var sqtl_fig_data = ref(null)

    var sqtl_gene_list = ref([])
    // prettier-ignore
    var sqtl_tissue_list = ref([])

    var SMRsqtlChartInit = () => {
      let Chart = echarts.init(SMRsqtlChart.value);
      let gridSize = [Chart.getWidth()/eqtl_gene_list.value.length, Chart.getHeight()/eqtl_tissue_list.value.length]
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Tissue: ' +
              sqtl_tissue_list.value[params.value[1]] +
              '<br>Gene: ' +
              sqtl_gene_list.value[params.value[0]] +
              '<br>-log10(P-value of SMR): ' +
              params.value[3].toFixed(4)
            );
          }
        },
        grid: {
          containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: sqtl_gene_list.value,
          name:'Gene',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -90
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: sqtl_tissue_list.value,
          name:'Tissue',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'SMR_sQTL',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return [Chart.getWidth()/sqtl_gene_list.value.length/2, Chart.getHeight()/sqtl_tissue_list.value.length/2]
            },
            data: sqtl_fig_data.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0,
          max: MaxSP.value,
          dimension: 3,
          right: 0,
          precision:0,
          top: 'center',
          text: ['', '-log10(P)'],
          calculable: true,
          inRange: {
            color: ['#0055ff', '#ff0004']
          }
        },
      };
      Chart.setOption(options);
    }

    var filter_sqtl_data = ref([])
    var sqtl_tissue = ref('')
    var sqtl_tissue_options = ref([])
    var sqtl_gene = ref('')
    var sqtl_gene_options = ref([])
    var sqtl_top_SNP = ref('')
    var sqtl_top_SNP_options = ref([])

    var filter_sqtl = () => {
      filter_sqtl_data.value = sqtl_data.value.filter(
        (data) => {
          return (!sqtl_tissue.value || data.Tissue == sqtl_tissue.value) &&
          (!sqtl_gene.value || data.Gene == sqtl_gene.value) &&
          (!sqtl_top_SNP.value || data.topSNP == sqtl_top_SNP.value)
        }
      )
      sqtl_Total.value = filter_sqtl_data.value.length
      sqtl_currentPage.value = 1
    }

    var reset_sqtl = () => {
      sqtl_tissue.value = ''
      sqtl_gene.value = ''
      sqtl_top_SNP.value = ''
      filter_sqtl_data.value = sqtl_data.value
      sqtl_Total.value = filter_sqtl_data.value.length
      sqtl_currentPage.value = 1
    }

    var sqtl_Total = ref(0)
    var sqtl_currentPage = ref(1)
    var sqtl_pageSize = ref(5)

    const sqtl_pagedData = computed(() => {
      const startIndex = (sqtl_currentPage.value - 1) * sqtl_pageSize.value;
      const endIndex = startIndex + sqtl_pageSize.value;
      return filter_sqtl_data.value.slice(startIndex, endIndex);
    });

    
    // 处理页码改变事件
    const handle_sqtl_CurrentChange = (newPage) => {
      sqtl_currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handle_sqtl_SizeChange = (newSize) => {
      sqtl_pageSize.value = newSize;
      sqtl_currentPage.value = 1;
    };

    const fetchsQTLData = async () => {
      Sloading.value = true
      try {
        const response = await proxy.$request.get('/smr_sQTL',{ 
          params:{
            study:route.params.study
        }})
        sqtl_data.value = response.data
        console.log(sqtl_data.value)
        if (sqtl_data.value.length == 0){
          showSqtl.value = false
        }
      } catch (error) {
        console.log('fail', error.message)
      }
      Sloading.value = false
    }

    watch(eqtl_data,(newValue, oldValue)=>{
      if (eqtl_data.value.length == 0){
        return 
      }
      let geneSet = new Set()
      let tissueSet = new Set()
      let topSNPSet = new Set()
      for (let i = 0; i < eqtl_data.value.length; i++){
        geneSet.add(eqtl_data.value[i].Gene)
        tissueSet.add(eqtl_data.value[i].Tissue)
        topSNPSet.add(eqtl_data.value[i].topSNP)
      }
      eqtl_gene_list.value = [...geneSet]
      eqtl_tissue_list.value = [...tissueSet]
      let topSNP_list = [...topSNPSet]
      eqtl_gene_options.value = eqtl_gene_list.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtl_tissue_options.value = eqtl_tissue_list.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtl_top_SNP_options.value = topSNP_list.map((item) => {
        return {
          value:item,
          label:item
        }
      })

      var tmp = 0
      eqtl_fig_data.value = eqtl_data.value.map((item) => {
        return [
          eqtl_gene_list.value.indexOf(item.Gene),
          eqtl_tissue_list.value.indexOf(item.Tissue),
          item.p_SMR,
          -1.0*(Math.log10(item.p_SMR)),
          tmp = -1.0*(Math.log10(item.p_SMR)) > tmp ? -1.0*(Math.log10(item.p_SMR)) : tmp
        ]
      })
      MaxEP.value = tmp
      filter_eqtl()
    })

    watch(filter_eqtl_data,(newValue, oldValue)=>{
      if (filter_eqtl_data.value.length != 0){
        let geneSet = new Set()
        let tissueSet = new Set()
        let topSNPSet = new Set()
        for (let i = 0; i < filter_eqtl_data.value.length; i++){
          geneSet.add(filter_eqtl_data.value[i].Gene)
          tissueSet.add(filter_eqtl_data.value[i].Tissue)
          topSNPSet.add(filter_eqtl_data.value[i].topSNP)
        }
        eqtl_gene_list.value = [...geneSet]
        eqtl_tissue_list.value = [...tissueSet]

        var tmp = 0
        eqtl_fig_data.value = filter_eqtl_data.value.map((item) => {
          return [
            eqtl_gene_list.value.indexOf(item.Gene),
            eqtl_tissue_list.value.indexOf(item.Tissue),
            item.p_SMR,
            -1.0*(Math.log10(item.p_SMR)),
            tmp = -1.0*(Math.log10(item.p_SMR)) > tmp ? -1.0*(Math.log10(item.p_SMR)) : tmp
          ]
        })
        MaxEP.value = tmp
        SMReqtlChartInit()
      }
    })
    
    watch(sqtl_data,(newValue, oldValue)=>{
      if (sqtl_data.value.length == 0){
        return
      }
      let geneSet = new Set()
      let tissueSet = new Set()
      let topSNPSet = new Set()
      for (let i = 0; i < sqtl_data.value.length; i++){
        geneSet.add(sqtl_data.value[i].Gene)
        tissueSet.add(sqtl_data.value[i].Tissue)
        topSNPSet.add(sqtl_data.value[i].topSNP)
      }
      sqtl_gene_list.value = [...geneSet]
      sqtl_tissue_list.value = [...tissueSet]
      let topSNP_list = [...topSNPSet]
      sqtl_gene_options.value = sqtl_gene_list.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      sqtl_tissue_options.value = sqtl_tissue_list.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      sqtl_top_SNP_options.value = topSNP_list.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      filter_sqtl()
    })

    watch(filter_sqtl_data,(newValue, oldValue)=>{
      if(filter_sqtl_data.value.length != 0){
        let geneSet = new Set()
        let tissueSet = new Set()
        let topSNPSet = new Set()
        for (let i = 0; i < filter_sqtl_data.value.length; i++){
          geneSet.add(filter_sqtl_data.value[i].Gene)
          tissueSet.add(filter_sqtl_data.value[i].Tissue)
          topSNPSet.add(filter_sqtl_data.value[i].topSNP)
        }
        sqtl_gene_list.value = [...geneSet]
        sqtl_tissue_list.value = [...tissueSet]

        var tmp = 0
        sqtl_fig_data.value = filter_sqtl_data.value.map((item) => {
          return [
            sqtl_gene_list.value.indexOf(item.Gene),
            sqtl_tissue_list.value.indexOf(item.Tissue),
            item.p_SMR,
            -1.0*(Math.log10(item.p_SMR)),
            tmp = -1.0*(Math.log10(item.p_SMR)) > tmp ? -1.0*(Math.log10(item.p_SMR)) : tmp
          ]
        })

        MaxSP.value = tmp
        SMRsqtlChartInit()
      }
    })
    
    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    onMounted(() => {
      fetcheQTLData()
      fetchsQTLData()
    })

    return {
      Eloading,
      Sloading,
      showEqtl,
      showSqtl,
      trait,
      SMReqtlChart,
      SMRsqtlChart,
      eqtl_pagedData,
      eqtl_tissue,
      eqtl_tissue_options,
      eqtl_gene,
      eqtl_gene_options,
      eqtl_top_SNP,
      eqtl_top_SNP_options,
      eqtl_Total,
      eqtl_currentPage,
      eqtl_pageSize,

      sqtl_pagedData,
      sqtl_tissue,
      sqtl_tissue_options,
      sqtl_gene,
      sqtl_gene_options,
      sqtl_top_SNP,
      sqtl_top_SNP_options,
      sqtl_Total,
      sqtl_currentPage,
      sqtl_pageSize,

      filter_eqtl,
      reset_eqtl,
      handle_eqtl_CurrentChange,
      handle_eqtl_SizeChange,
      filter_sqtl,
      reset_sqtl,
      handle_sqtl_CurrentChange,
      handle_sqtl_SizeChange,
      sortEqtlChange,
      sortSqtlChange,
      NAFormat
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .bar{
    width: 100%;
    height: 500px;
  }
</style>