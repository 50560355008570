<template>
  <h2 style="text-align: center;font-size: 32px;">The COLOC results for {{ trait }}</h2>
  <el-divider />
  <div class="coloceqtl">
    <h2 style="text-align: center;font-size: 32px;">Colocalized genes of {{ trait }} by analysis on eQTL</h2>
    <div ref="coloceqtlChart" class="bar"></div>
    <div>
      <div class="table_search">
        <div class="table_select">
          <div class="search_title">Tissue:</div>
          <div>
            <el-select
              v-model="eqtl_tissue"
              placeholder="input and select a tissue"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtl_tissue_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">Gene:</div>
          <div>
            <el-select
              v-model="eqtl_gene"
              placeholder="input and select a gene"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtl_gene_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="table_select">
          <div class="search_title">SNP:</div>
          <div>
            <el-select
              v-model="eqtl_SNP"
              placeholder="input and select a top SNP"
              filterable
              style="width: 240px">
              <el-option
                v-for="item in eqtl_SNP_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="search_button">
            <el-button type="primary" @click="filter_eqtl">Search</el-button>
            <el-button @click="reset_eqtl">Reset</el-button>
          </div>
      </div>
      <div class="table_container">
        <el-table 
          :data="pagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortChange"
        >
          <el-table-column prop="Study" label="Study" min-width="150" />
          <el-table-column prop="Trait" label="Trait" min-width="150" />
          <el-table-column prop="Tissue" label="Tissue" min-width="150" />
          <el-table-column prop="Gene" label="Gene" min-width="150">
            <template v-slot="scope">
              <a v-if="scope.row.Gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.Gene" target="_blank">{{scope.row.Gene}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="Chr" label="Chr" min-width="150" />
          <el-table-column sortable prop="SNPs" label="#SNPs" min-width="150" /> 
          <el-table-column sortable prop="pp_h4_abf" label="pp_h4_abf" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="pp4_pp3" label="pp4_pp3" min-width="150" :formatter="NAFormat" /> 
          <el-table-column prop="coloc_snp" label="SNP" min-width="150" >
            <template v-slot="scope">
              <a v-if="scope.row.coloc_snp" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.coloc_snp" target="_blank">{{scope.row.coloc_snp}}</a>
            </template>
          </el-table-column>
          <el-table-column sortable prop="GWAS_P" label="GWAS_P" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="QTL_P" label="eQTL_P" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="snp_pp_h4" label="snp_pp_h4" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="pp_h0_abf" label="pp_h0_abf" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="pp_h1_abf" label="pp_h1_abf" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="pp_h2_abf" label="pp_h2_abf" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="pp_h3_abf" label="pp_h3_abf" min-width="150" :formatter="NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="eqtl_currentPage"
          :total="eqtl_Total"
          :page-sizes="[5,10,20]"
          :page-size="eqtl_pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
      </div>
    </div>
  </div>
</template>

<script>
import { ElDivider, ElTable, ElTableColumn, ElSelect, ElOption, ElButton, ElPagination } from 'element-plus';
import * as echarts from 'echarts'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';

export default {
  name:'COLOC',
  components: {
    ElDivider,
    ElTable, 
    ElTableColumn,ElSelect, 
    ElOption, 
    ElButton,
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const trait = ref(route.params.study)
    const coloceqtlChart = ref(null)

    var eqtl_data = ref(null)
    var eqtl_fig_data = ref(null)

    var eqtl_gene_list = ref([])
    // prettier-ignore
    var eqtl_tissue_list = ref([])

    var coloceqtlChartInit = () => {
      let Chart = echarts.init(coloceqtlChart.value);
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Tissue: ' +
              eqtl_tissue_list.value[params.value[1]] +
              '<br>Gene: ' +
              eqtl_gene_list.value[params.value[0]] +
              '<br>pp_h4_abf: ' +
              params.value[2].toFixed(4)
            );
          }
        },
        grid: {
          containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: eqtl_gene_list.value,
          name:'Gene',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -90
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: eqtl_tissue_list.value,
          name:'Tissue',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'SMR_eQTL',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return [Chart.getWidth()/eqtl_gene_list.value.length/2, Chart.getHeight()/eqtl_tissue_list.value.length/2]
            },
            data: eqtl_fig_data.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0.75,
          max: 1,
          dimension: 2,
          right: 0,
          precision:2,
          top: 'center',
          text: ['', 'pp_h4_abf'],
          calculable: true,
          inRange: {
            color: ['#0055ff', '#ff0004']
          }
        },
      };
      Chart.setOption(options);
    }

    var eqtl_gene = ref('')
    var eqtl_gene_options = ref([])
    var eqtl_tissue = ref('')
    var eqtl_tissue_options = ref([])
    var eqtl_SNP = ref('')
    var eqtl_SNP_options = ref([])
    var filter_eqtl_data = ref([])

    var eqtl_Total = ref(0)
    var eqtl_currentPage = ref(1)
    var eqtl_pageSize = ref(5)

    const pagedData = computed(() => {
      const startIndex = (eqtl_currentPage.value - 1) * eqtl_pageSize.value;
      const endIndex = startIndex + eqtl_pageSize.value;
      return filter_eqtl_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      eqtl_currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      eqtl_pageSize.value = newSize;
      eqtl_currentPage.value = 1
    };

    const sortChange = (arg) => {
      let sortData = filter_eqtl_data.value
      if (arg.order === 'descending') {
          sortData.sort(sortByProp(arg.prop, true))
      }else{
          sortData.sort(sortByProp(arg.prop, false))
      }
      filter_eqtl_data.value = sortData
      eqtl_currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    var filter_eqtl = () => {
      filter_eqtl_data.value = eqtl_data.value.filter(
        (data) => {
          return (!eqtl_tissue.value || data.Tissue==eqtl_tissue.value) &&
          (!eqtl_gene.value || data.Gene == eqtl_gene.value) &&
          (!eqtl_SNP.value || data.coloc_snp == eqtl_SNP.value)
        }
      )
      eqtl_Total.value = filter_eqtl_data.value.length
      eqtl_currentPage.value = 1
    }

    var reset_eqtl = () => {
      eqtl_tissue.value = ''
      eqtl_gene.value = ''
      eqtl_SNP.value = ''
      filter_eqtl_data.value = eqtl_data.value
      eqtl_Total.value = filter_eqtl_data.value.length
      eqtl_currentPage.value = 1
    }
    
    const fetcheQTLData = async () => {
      try {
        const response = await proxy.$request.get('/coloc_eQTL',{ 
          params:{
            study:route.params.study
        }})
        eqtl_data.value = response.data
        console.log(eqtl_data.value)
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    watch(eqtl_data,(newValue, oldValue)=>{
      let geneSet = new Set()
      let tissueSet = new Set()
      let SNPSet = new Set()
      for (let i = 0; i < eqtl_data.value.length; i++){
        geneSet.add(eqtl_data.value[i].Gene)
        tissueSet.add(eqtl_data.value[i].Tissue)
        SNPSet.add(eqtl_data.value[i].coloc_snp)
      }
      eqtl_gene_list.value = [...geneSet]
      eqtl_tissue_list.value = [...tissueSet]
      let SNP_list = [...SNPSet]
      eqtl_gene_options.value = eqtl_gene_list.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtl_tissue_options.value = eqtl_tissue_list.value.map((item) => {
        return {
          value:item,
          label:item
        }
      })
      eqtl_SNP_options.value = SNP_list.map((item) => {
        return {
          value:item,
          label:item
        }
      })

      eqtl_fig_data.value = eqtl_data.value.map((item) => {
        return [
          eqtl_gene_list.value.indexOf(item.Gene),
          eqtl_tissue_list.value.indexOf(item.Tissue),
          item.pp_h4_abf,
          item.pp4_pp3
        ]
      })
      filter_eqtl()
      coloceqtlChartInit()
    })

    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    watch(filter_eqtl_data,(newValue, oldValue)=>{
      if (filter_eqtl_data.value.length != 0){
        let geneSet = new Set()
        let tissueSet = new Set()
        let topSNPSet = new Set()
        for (let i = 0; i < filter_eqtl_data.value.length; i++){
          geneSet.add(filter_eqtl_data.value[i].Gene)
          tissueSet.add(filter_eqtl_data.value[i].Tissue)
          topSNPSet.add(filter_eqtl_data.value[i].topSNP)
        }
        eqtl_gene_list.value = [...geneSet]
        eqtl_tissue_list.value = [...tissueSet]

        eqtl_fig_data.value = filter_eqtl_data.value.map((item) => {
          return [
            eqtl_gene_list.value.indexOf(item.Gene),
            eqtl_tissue_list.value.indexOf(item.Tissue),
            item.pp_h4_abf,
          item.pp4_pp3
          ]
        })
        coloceqtlChartInit()
      }
    })
    
    onMounted(() => {
      fetcheQTLData()
    })

    return {
      trait,
      coloceqtlChart,
      eqtl_gene,
      eqtl_gene_options,
      eqtl_tissue,
      eqtl_tissue_options,
      eqtl_SNP,
      eqtl_SNP_options,
      eqtl_Total,
      eqtl_currentPage,
      eqtl_pageSize,
      pagedData,


      handleCurrentChange,
      handleSizeChange,
      filter_eqtl,
      reset_eqtl,
      sortChange,
      NAFormat
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>