<template>
  <el-tabs v-loading="loadingimg" type="card">
    <el-tab-pane label="zoom 1x">
      <img :src="zoom1" alt="zoom 1x" style="width:60%">
    </el-tab-pane>
    <el-tab-pane label="zoom 4x">
      <img :src="zoom4" alt="zoom 4x" style="width:60%">      
    </el-tab-pane>
    <el-tab-pane label="zoom 10x">
      <img :src="zoom10" alt="zoom 10x" style="width:60%">    
    </el-tab-pane>
  </el-tabs>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">SNP:</div>
      <el-select-v2
        v-model="snp"
        placeholder="input and select a SNP"
        filterable
        :options="snp_options"
        style="width: 240px"
      />
    </div>
    <div class="table_select">
      <div class="table_select">
        <div class="search_title">P {{ '<' }}</div>
        <el-input v-model="p" style="width: 240px" type="number" placeholder="input P" />
      </div>        
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filterBySNPorP">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loadingtable"
        :data="pagedData"
        :border="true"
        style="width: 100%"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column sortable prop="SNP" label="SNP" min-width="75">
          <template v-slot="scope">
            <a v-if="scope.row.SNP" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.SNP" target="_blank">{{scope.row.SNP}}</a>
          </template>
        </el-table-column> 
        <el-table-column sortable prop="CHR" label="Chr" min-width="75"/>
        <el-table-column sortable prop="POS" label="Position" min-width="125"/>
        <el-table-column sortable prop="P" label="P" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="Effect" label="Effect" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="StdErr" label="SE" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="A1" label="A1" min-width="75"/>
        <el-table-column sortable prop="A2" label="A2" min-width="75"/>
        <el-table-column sortable prop="Freq" label="Frequency" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="proportion_cases" label="Proportion Cases" min-width="125" :formatter="NAFormat"/> 
        <el-table-column sortable prop="N" label="N" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="tstat" label="T Statistics" min-width="125" :formatter="NAFormat"/>
        <el-table-column prop="leadSNP" label="Lead SNP" min-width="125"/>
        <el-table-column sortable prop="MAF" label="MAF" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="ABF.CS" label="ABF CS" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="ABF.PP" label="ABF PP" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="FINEMAP.CS" label="FINEMAP CS" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="FINEMAP.PP" label="FINEMAP PP" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="SUSIE.CS" label="SUSIE CS" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="SUSIE.PP" label="SUSIE PP" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="Support" label="Support" min-width="125" :formatter="NAFormat"/>
        <el-table-column prop="Consensus_SNP" label="Consensus SNP" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="mean.CS" label="mean CS" min-width="125" :formatter="NAFormat"/>
        <el-table-column sortable prop="mean.PP" label="mean PP" min-width="125" :formatter="NAFormat"/>

      </el-table>  
      
      <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      /> 
    </div>
  </div>
</template>

<script>
import { ElTabs, ElTabPane, ElSelectV2, ElOption, ElTable, ElTableColumn, ElButton, ElInput, ElDivider, ElPagination } from 'element-plus';
import { ref, computed, watch, onMounted, getCurrentInstance } from 'vue';

export default {
  name:'Finemap',
  components:{
    ElTabs, 
    ElTabPane, 
    ElSelectV2, 
    ElOption, 
    ElTable, 
    ElTableColumn, 
    ElButton, 
    ElInput, 
    ElDivider, 
    ElPagination
  },
  props:{
    study: String,
    locus: String
  },
  setup(props){
    const { proxy } = getCurrentInstance()
    var loadingimg = ref(false)
    var loadingtable = ref(false)
    var zoom1 = ref('')
    var zoom4 = ref('')
    var zoom10 = ref('')

    var snp = ref('')
    var snp_options = ref([])
    var p = ref(null)

    var table_data = ref([])
    var filter_table_data = ref('')

    var filterBySNPorP = () => {
      filter_table_data.value = table_data.value.filter(
        (data) => {
          return (!snp.value || data.SNP == snp.value) 
          &&
          (!p.value || data.P < p.value)
        }
      )
      Total.value = filter_table_data.value.length
    }

    var reset = () => {
      snp.value = ''
      p.value = null
      filter_table_data.value = table_data.value
      Total.value = filter_table_data.value.length
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    const pagedData = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize.value;
      const endIndex = startIndex + pageSize.value;
      return filter_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };
    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    const sortChange = (arg) => {
      let sortData = filter_table_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'SNP' || arg.prop === 'A1'|| arg.prop === 'A2'){
          sortData.sort(sortByName(arg.prop, true))
        }else{
          sortData.sort(sortByProp(arg.prop, true))

        }
      }else{
        if (arg.prop === 'SNP' || arg.prop === 'A1'|| arg.prop === 'A2'){
          sortData.sort(sortByName(arg.prop, false))
        }else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filter_table_data.value = sortData
      currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    const fetchData = async () => {
      loadingtable.value = true
      try {
        const response = await proxy.$request.get('/finemap',{ 
          params:{
            study:props.study,
            locus:props.locus.split('_')[1]
        }})
        table_data.value = response.data
        snp_options.value = response.data.map((item) => {
          return {
            value:item.SNP,
            label:item.SNP
          }
        })
        loadingtable.value = false
      } catch (error) {
        console.log('fail', error.message)
        loadingtable.value = false
      }
    }
    const fetchPNG = async () => {
      loadingimg.value = true
      try {
        const response = await proxy.$request.get('/finemap_png',{ 
          params:{
            study:props.study,
            locus:props.locus.split('_')[1]
        }})
        zoom1.value = response.data.zoom1
        zoom4.value = response.data.zoom4
        zoom10.value = response.data.zoom10
        loadingimg.value = false
      } catch (error) {
        console.log('fail', error.message)
        loadingimg.value = false
      }
    }

    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    watch(() => [props.study, props.locus], ([newStudy, oldStudy], [newLocus, oldLocus]) => {
      fetchData()
      fetchPNG()
      currentPage.value = 1
    })

    watch(table_data, (newValue, oldValue) => {
      filterBySNPorP()
      console.log(pagedData.value)
    })

    return {
      loadingtable,
      loadingimg,
      zoom1,
      zoom4,
      zoom10,
      snp,
      snp_options,
      p,
      pagedData,
      currentPage,
      pageSize,
      Total,

      filterBySNPorP,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      NAFormat
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>