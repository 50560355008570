<template>
  <h2 style="text-align: center;font-size: 32px;">Search results for keyword: {{ displayWord }}</h2>
  <el-divider />
  <div class="search">
      <div class="search_box">
        <el-input
          v-model="searchWord"
          style="width: 100%"
          placeholder="Enter Gene or SNP, Example: APOE"
          @keyup.enter = "Search"
          clearable
          size="large"
        />
      </div>
      <button class="search_btn" @click="Search">
        <SearchOutlined class="search_icon"/>
      </button>
  </div>
  <div style="display: flex;justify-content: center;">
    <div style="width: 52%;">
      <div class="radio_select">
        <div>Search by:</div>
        <el-radio-group style="margin-left: 20px;" v-model="searchKey">
          <el-radio :value="'Gene'">Gene</el-radio>
          <el-radio :value="'SNP'">SNP</el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
  <el-divider />
  <div class="table_search">
      <div class="table_select">
        <div class="search_title">Trait:</div>
        <el-select
          v-model="trait"
          placeholder="select a trait"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in trait_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="table_select">
        <div class="search_title">Method:</div>
        <el-select
          v-model="method"
          placeholder="select a method"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in method_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="search_button">
        <el-button type="primary" @click="filterByTraitorMethod">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loading"
        :data="pagedData"
        :border="true"
        style="width: 100%; overflow-y: auto"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
      >
        <el-table-column prop="Study" label="Study" min-width="125" />
        <el-table-column prop="Trait" label="Trait" min-width="125" />
        <el-table-column prop="Tissue" label="Tissue" min-width="125" :formatter="tissueFormat" />
        <el-table-column prop="Gene" label="Gene" min-width="125" >
          <template v-slot="scope">
            <a v-if="scope.row.Gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.Gene" target="_blank">{{scope.row.Gene}}</a>
          </template>
        </el-table-column>     
        <el-table-column prop="SNP" label="SNP" min-width="125" :formatter="SNPFormat">
          <template v-slot="scope">
            <a v-if="scope.row.SNP" class="pmid" :href="'https://www.ncbi.nlm.nih.gov/snp/?term='+scope.row.SNP" target="_blank">{{scope.row.SNP}}</a>
          </template>
        </el-table-column>    
        <el-table-column prop="StatisticsName" label="Statistics Name" min-width="125" />
        <el-table-column prop="StatisticsValue" label="Statistics Value" min-width="125" />   
        <el-table-column prop="Method" label="Method" min-width="125">
          <template v-slot="scope">
            <router-link
              class="pmid"
              :key="scope.row.Method"
              :to="{
                name:scope.row.Method == 'FINEMAP' ? 'FineMapping' : scope.row.Method,
                params:{
                  study:scope.row.Study
                }
              }"
              target="_blank"
              >
              {{ scope.row.Method }}
            </router-link>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      /> 
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed, getCurrentInstance, watch } from 'vue'
import { ElSelect, ElOption, ElButton, ElInput, ElDivider, ElTable, ElTableColumn, ElPagination, ElRadioGroup, ElRadio, formatter } from 'element-plus';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons-vue'
import { useRoute } from 'vue-router';

export default {
  name:'Search',
  components:{
    ElSelect, 
    ElOption, 
    ElButton,
    ElInput,
    ElDivider,
    ElTable, 
    ElTableColumn, 
    ElPagination,
    CloseCircleOutlined,
    SearchOutlined,
    ElRadioGroup, 
    ElRadio
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    var loading = ref(false)
    var searchWord = ref('')
    var displayWord = ref('')
    var searchKey = ref('Gene')
    var isSearching = ref(false)
    var method = ref('')
    var method_options = ref([])
    var trait = ref('')
    var trait_options = ref([])
    var filter_table_data = ref([])

    function cleanSearch(){
      searchWord.value = ''
    }

    var table_data = ref([])

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    const pagedData = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize.value;
      const endIndex = startIndex + pageSize.value;
      return filter_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    var filterByTraitorMethod = () => {
      filter_table_data.value = table_data.value.filter(
        (data) => {
          return (!trait.value || data.Trait.toLowerCase() === trait.value.toLowerCase()) 
          &&
          (!method.value || data.Method === method.value)
        }
      )
      Total.value = filter_table_data.value.length
    }
    var reset = () => {
      trait.value = ''
      // method.value = ''
      filter_table_data.value = table_data.value
      Total.value = filter_table_data.value.length
    }

    const fetcheData = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/searchAll',{ 
          params:{
            searchKey: searchKey.value,
            searchWord: searchWord.value
        }})
        table_data.value = response.data
        console.log(table_data)
        writeOption()
        filterByTraitorMethod()
        isSearching.value = false
        loading.value = false
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const writeOption = () => {
      let traitSet = new Set(table_data.value.map(item => item.Trait))
      trait_options.value = Array.from(traitSet).sort().map(item => ({
        label:item,
        value:item
      }))
      let methodSet = new Set(table_data.value.map(item => item.Method))
      method_options.value = Array.from(methodSet).sort().map((item) => {
        return {
          label:item,
          value:item
        }
      })
    }
    
    const Search = () => {
      if (isSearching.value) {
        return;
      }
      isSearching.value = true
      if (searchWord.value == ''){
        alert('searchWord can not be null!')
        isSearching.value = false
      }
      else{
        fetcheData()
        displayWord.value = searchWord.value
      }
    }
    
    const tissueFormat = (row) => {
      let showProp = null
      row.Tissue ? showProp = row.Tissue : showProp = '-'
      return showProp
    }

    const SNPFormat = (row) => {
      let showProp = null
      row.SNP ? showProp = row.SNP : showProp = '-'
      return showProp
    }

    const methodFormat = (row) => {
      let showProp = null
      if(row.StatisticsName == "ABF PP" || row.StatisticsName == "FINEMAP PP" || row.StatisticsName == "SuSiE PP"){
        showProp = 'FINEMAP'
      }else if(row.StatisticsName == "JTI P" || row.StatisticsName == "UTMOST P" || row.StatisticsName == "PrediXcan P"){
        showProp = "TWAS"
      }else if (row.StatisticsName == "SMR_eqtl P" || row.StatisticsName == "SMR_sqtl P"){
        showProp = "SMR"
      }else if (row.StatisticsName == "COLOC pp_h4_abf"){
        showProp = "COLOC"
      }else{
        showProp = "MAGMA"
      }
      return showProp
    }

    const filterhandler = (value, row, column) => {
      console.log('c',column)
      console.log('v',value)
      console.log('r',row)
      const property = column['property']
      return row[property] == value
    }

    onMounted(() => {
      if(route.query.Gene){
        searchWord.value = route.query.Gene,
        searchKey.value = 'Gene',
        Search()
      }else if(route.query.Variant){
        searchWord.value = route.query.Variant,
        searchKey.value = 'SNP',
        Search()
      }
    })

    return {
      searchWord,
      displayWord,
      searchKey,
      loading,
      method,
      method_options,
      trait,
      trait_options,

      currentPage,
      pageSize,
      Total,
      pagedData,

      handleCurrentChange,
      handleSizeChange,
      cleanSearch,
      Search,
      tissueFormat,
      SNPFormat,
      methodFormat,
      filterByTraitorMethod,
      reset,
      filterhandler
    }
  }
}
</script>

<style scoped>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
  .search{
    display: flex;
    justify-content: center;
    text-align: center;
    align-content: center;
  }
  .search_box{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 45%;
  }
  .radio_select{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  input{
    width:100%;
    height: 25px;
    font-size: 16px;
    border: none;
    outline:none
  }
  input:focus{
    outline:none
  }
  input::placeholder{
    color: #d0d0d0;
  }
  .clean_icon{
    color:#b2b2b2
  }
  .clean_icon:hover{
    color:#606060
  }
  .search_icon{
    color: white;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
  .search_btn{
    border: 1px solid #0c64b6;
    border-radius: 0 2px 2px 0;
    background-color: #0c64b6;
    transition: border 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
    width: 50px;
  }
  .search_btn:hover{
    border: 1px solid #127fe5;
    background-color: #127fe5;
    cursor: pointer;
  }
</style>