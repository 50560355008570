<template>
  <div class="heritability">
    <h2 style="text-align: center;font-size: 32px">The SNP-heritability computed by LDSC and LDAK for {{ trait }}</h2>
    <div ref="h2Chart" class="bar"></div>
    <div>
      <div  class="table_container">
        <el-table 
          :data="h2_table_data"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
        >
          <el-table-column prop="STUDY" label="Study" min-width="150" />
          <el-table-column prop="TRAIT" label="Tarit" min-width="150" />
          <el-table-column prop="LDAK_H2" label="LDAK h2" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDAK_H2_SD" label="LDAK h2_sd" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDAK_P" label="LDAK h2_p" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDAK_INFLU" label="LDAK influence" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDAK_INFLU_SD" label="LDAK influence_sd" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDSC_H2" label="LDSC h2" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDSC_H2_SE" label="LDSC h2_se" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDSC_Z" label="LDSC h2_zsocre" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDSC_GC" label="LDSC gc" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="LDSC_MEAN_CHI2" label="LDSC mean chi2" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="INTERCEPT" label="LDSC intercept" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="INTERCEPT_SE" label="LDSC intercept_se" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="RATIO" label="LDSC ratio" min-width="150" :formatter="NAFormat" />
          <el-table-column prop="RATIO_SE" label="LDSC ratio_se" min-width="150" :formatter="NAFormat" />
        </el-table>
      </div>
    </div>
  </div>
  <el-divider />
  <div class="heritability_enrichment">
    <h2 style="text-align: center;font-size: 32px">The heritability enrichment computed by LDAK for {{ trait }}</h2>
    <div ref="h2EnrichChart" class="bar"></div>
    <div>
      <div class="table_container">
        <el-table 
          :data="HEpagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortLDAKChange"
        >
          <el-table-column prop="STUDY" label="Study" min-width="150" />
          <el-table-column prop="TRAIT" label="Tarit" min-width="150" />
          <el-table-column sortable prop="COMPONENT" label="Component" min-width="150"  />
          <el-table-column sortable prop="SHARE" label="Share" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="SHARE_SD" label="LDSC h2_zsocre" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="EXPECTED" label="Expected" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="ENRICHMENT" label="Enrichment" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="ENRICHMENT_SD" label="Enrichment SD" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="ENRICHMENT_P" label="Enrichment P" min-width="150" :formatter="NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleHESizeChange"
          @current-change="handleHECurrentChange"
          :current-page="currentHEPage"
          :total="h2EnrichTotal"
          :page-sizes="[5,10,20]"
          :page-size="HEpageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        />
      </div>
    </div>
  </div>
  <el-divider />
  <div class="genetic_correlation">
    <h2 style="text-align: center;font-size: 32px">The genetic correlation between traits</h2>
    <div class="geneCor">
      <div ref="geneCorChart1" class="bar"></div>
      <div ref="geneCorChart2" class="bar"></div>
    </div>
    <div class="table_search">
      <div class="table_select">
        <div class="search_title">Trait 2:</div>
        <div>
          <el-select
            v-model="study2"
            placeholder="input and select a Study 2"
            filterable
            style="width: 240px">
            <el-option
              v-for="item in study_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="table_select">
        <div class="search_title">LDAK RG  ></div>
        <el-input v-model="LDAK_RG" style="width: 240px" type="number" placeholder="input LDAK RG" />
      </div>
      <div class="table_select">
        <div class="search_title">LDAK P  {{ '<' }}</div>
        <el-input v-model="LDAK_P" style="width: 240px" type="number" placeholder="input LDAK P" />
      </div>
      <div class="table_select">
        <div class="search_title">LDSC RG  ></div>
        <el-input v-model="LDSC_RG" style="width: 240px" type="number" placeholder="input LDSC RG" />
      </div>
      <div class="table_select">
        <div class="search_title">LDSC P  {{ '<' }}</div>
        <el-input v-model="LDSC_P" style="width: 240px" type="number" placeholder="input LDSC P" />
      </div>
      <div class="search_button">
          <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
          <el-button @click="reset">Reset</el-button>
        </div>
    </div>
    <div>
      <div  class="table_container">
        <el-table 
          :data="HGCpagedData"
          :border="true"
          style="width: 100%; overflow-y: auto"
          :header-cell-style="{backgroundColor:'#F6F6F6'}"
          @sort-change="sortGCChange"
        >
          <el-table-column prop="STUDY1" label="Study 1" min-width="150" />
          <el-table-column prop="TRAIT1" label="Trait 1" min-width="150" />
          <el-table-column sortable prop="STUDY2" label="Study 2" min-width="150" />
          <el-table-column sortable prop="TRAIT2" label="Trait 2" min-width="150" />
          <el-table-column sortable prop="LDAK_GC" label="LDAK RG" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="LDAK_GC_SD" label="LDAK RG SD" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="LDAK_GC_P" label="LDAK RG P" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="LDSC_GC" label="LDSC RG" min-width="150" :formatter="NAFormat" />
          <el-table-column sortable prop="LDSC_GC_SE" label="LDSC RG SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_GC_Z" label="LDSC RG Z" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_GC_P" label="LDSC RG P" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDAK_1_HER" label="LDAK Study1 HER" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDAK_1_HER_SD" label="LDAK study1 HER SD" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDAK_2_HER" label="LDAK Study2 HER" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDAK_2_HER_SD" label="LDAK Study2 HER SD" min-width="150" :formatter="NAFormat"  /> 
          <el-table-column sortable prop="LDSC_2_H2" label="LDSC Study2 h2" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_2_H2_SE" label="LDSC Study2 h2 SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_2_H2_INT" label="LDSC Study2 h2 intercept" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_2_H2_INT_SE" label="LDSC Study2 h2 intercept SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_GENCOV" label="LDSC gencov" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_GENCOV_SE" label="LDSC gencov SE" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_GENCOV_INT" label="LDSC gencov intercept" min-width="150" :formatter="NAFormat" /> 
          <el-table-column sortable prop="LDSC_GENCOV_INT_SE" label="LDSC gencov intercept SE" min-width="150" :formatter="NAFormat" /> 
        </el-table>

        <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleHGCSizeChange"
          @current-change="handleHGCCurrentChange"
          :current-page="currentHGCPage"
          :total="h2GCTotal"
          :page-sizes="[5]"
          :page-size="HGCpageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        />
      </div>
    </div>
  </div>
  <el-backtop :right="50" :bottom="50" />
</template>

<script>
import { ElDivider, ElSelect, ElOption, ElTable, ElTableColumn, ElButton, ElInput, ElPagination, ElBacktop } from 'element-plus';
import * as echarts from 'echarts'
import { reactive, ref, onMounted, computed, watch, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name:'heritability',
  components:{
    ElDivider,
    ElSelect, 
    ElOption, 
    ElTable, 
    ElTableColumn, 
    ElButton,
    ElInput,
    ElPagination,
    ElBacktop
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const trait = ref(route.params.study)
    const h2Chart = ref(null);
    const h2EnrichChart = ref(null)
    const geneCorChart1 = ref(null)
    const geneCorChart2 = ref(null)
    var h2_table_data = ref([])
    var h2_enrich_table_data = ref([])
    var h2_gencor_table_data = ref([])
    var h2_filter_genecor_data = ref([])
    var genCor1Data = ref([])
    var genCor2Data = ref([])
    var genCor1Link = ref([])
    var genCor2Link = ref([])
    var study_options = ref([])
    var study2 = ref('')
    var LDAK_RG = ref('')
    var LDAK_P = ref('')
    var LDSC_RG = ref('')
    var LDSC_P = ref('')

    var h2ChartInit = () => {
      let Chart = echarts.init(h2Chart.value);
      // 绘制图表
      let options = {
        tooltip: {
          formatter: function (params) {
            if(params.seriesType == 'custom'){
              return (
                params.seriesName+' error bar: '+params.value[2].toFixed(4)+'\t'+params.value[1].toFixed(4)
              )
            }
            else{
              return (
                params.seriesName+': '+params.value.toFixed(4)
              );
            }
          }
        },
        legend:{
          selectedMode:false,
          data:[
            {
              name:'LDAK'
            },
            {
              name:'LDSC'
            }
          ]
        },
        xAxis: {
          type:'category',
          data: ["AT011"],
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        series: [
          {
            name: "LDAK",
            itemStyle:{
              color:'#5f97d2'
            },
            type: "bar",
            data: [Number(h2_table_data.value[0].LDAK_H2)]
          },
          {
            name: "LDSC",
            itemStyle:{
              color:'#d68a56'
            },
            type: "bar",
            data: [Number(h2_table_data.value[0].LDSC_H2)]
          },
          {
            type:'custom',
            name:'LDSC',
            itemStyle: {
              borderWidth: 1.5,
              color:'#b55c21'
            },
            renderItem: function(params, api){
              var xValue = api.value(0);
              var highPoint = api.coord([xValue, api.value(1)]);
              var lowPoint = api.coord([xValue, api.value(2)]);
              var halfWidth = api.size([1,0])[0] * 0.1;
              var style = api.style({
                stroke:api.visual('color'),
                fill:undefined
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1:highPoint[0] + 1.3*halfWidth,
                      y1:highPoint[1],
                      x2:highPoint[0] + 2.7*halfWidth,
                      y2:highPoint[1]
                    },
                    style:style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] + 2*halfWidth,
                      y1: highPoint[1],
                      x2: lowPoint[0] + 2*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] + 1.3*halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] + 2.7*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  }
                ]
              };
            },
            encode: {
              x: 0,
              y: [1,2]
            },
            data: [[0, Number(h2_table_data.value[0].LDSC_H2)+Number(h2_table_data.value[0].LDSC_H2_SE), Number(h2_table_data.value[0].LDSC_H2)-Number(h2_table_data.value[0].LDSC_H2_SE)]],
            z:100
          },
          {
            type:'custom',
            name:'LDAK',
            itemStyle: {
              borderWidth: 1.5,
              color:'#176299'
            },
            renderItem: function(params, api){
              var xValue = api.value(0);
              var highPoint = api.coord([xValue, api.value(1)]);
              var lowPoint = api.coord([xValue, api.value(2)]);
              var halfWidth = api.size([1,0])[0] * 0.1;
              var style = api.style({
                stroke:api.visual('color'),
                fill:undefined
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1:highPoint[0] - 2.7*halfWidth,
                      y1:highPoint[1],
                      x2:highPoint[0] - 1.3*halfWidth,
                      y2:highPoint[1]
                    },
                    style:style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] - 2*halfWidth,
                      y1: highPoint[1],
                      x2: lowPoint[0] - 2*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] - 2.7*halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] - 1.3*halfWidth,
                      y2: lowPoint[1]
                    },
                    style: style
                  }
                ]
              };
            },
            encode: {
              x: 2,
              y: 1
            },
            data: [[0, Number(h2_table_data.value[0].LDAK_H2)+Number(h2_table_data.value[0].LDAK_H2_SD), Number(h2_table_data.value[0].LDAK_H2)-Number(h2_table_data.value[0].LDAK_H2_SD)]],
            z:100
          }
        ],
      }; 
      // 渲染图表
      Chart.setOption(options);
    }

    var region = ref([]);
    // prettier-ignore
    var Trait_list = ref([]);
    // prettier-ignore
    const HEdata = ref([]);

    var h2EnrichChartInit = () => {
      let Chart = echarts.init(h2EnrichChart.value);
      let options = {
        tooltip: {
          position: 'top',
          formatter: function (params) {
            return (
              'Enrichment: ' +
              params.value[2] +
              '<br>Enrichment P: ' +
              params.value[3]
            );
          }
        },
        grid: {
          bottom:'30%'
        },
        xAxis: {
          type: 'category',
          data: region.value,
          name:'Region',
          nameGap:20,
          axisLabel:{
            margin: 20,
            rotate: -40
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: Trait_list.value,
          name:'Trait',
          nameGap:20,
          axisLabel:{
            margin: 20
          },
          boundaryGap: false,
          splitLine: {
            show: true
          },
          axisLine: {
            show: false
          }
        },
        series: [
          {
            name: 'Enrichment',
            type: 'scatter',
            symbol: 'rect',
            symbolSize: function (val) {
              return val[2] > 0 ? Math.pow(val[2], 1/3)*5 : 0;
            },
            data: HEdata.value,
          }
        ],
        visualMap: {
          type:'continuous',
          min: 0,
          max: 1,
          dimension: 3,
          right: 0,
          precision:2,
          top: 'center',
          text: ['', 'P'],
          calculable: true,
          inRange: {
            color: ['#ff0004', '#0055ff']
          }
        },
      };
      Chart.setOption(options);
    }

    var geneCorChart1Init = () => {
      let Chart = echarts.init(geneCorChart1.value);
      let options = {
        animation:false,
        title:{
          text:'LDAK',
          left:'center',
          textStyle: {
            color:'black'
          }
        },
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'edge') {
              return 'RG: '+params.value[0]+
                     '<br>RG P: '+params.value[1]
            }else{
              return 'Trait: '+params.data.value
            }
          }
        },
        grid: {
        },
        series: [
          {
            name: 'genetic correlation',
            type: 'graph',
            layout: 'force',
            force: {
              center: route.params.study,
              repulsion: 5000,
              edgeLength: 50,
              initLayout: 'circular',
              layoutAnimation: true
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                textStyle: {
                  fontSize: 16,
                  color: 'black'
                }
              }
            },
            data: genCor1Data.value,
            links: genCor1Link.value
          }
        ],
      };
      Chart.setOption(options);
    }
    
    var geneCorChart2Init = () => {
      let Chart = echarts.init(geneCorChart2.value);
      let options = {
        title:{
          text:'LDSC',
          left:'center',
          textStyle: {
            color:'black'
          }
        },
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'edge') {
              return 'RG: '+params.value[0]+
                     '<br>RG P: '+params.value[1]
            }else{
              return 'Trait: '+params.data.value
            }
          }
        },
        grid: {
        },
        series: [
          {
            name: 'genetic correlation',
            type: 'graph',
            layout: 'force',
            force: {
              center: route.params.study,
              repulsion: 5000,
              edgeLength: 50,
              initLayout: 'circular',
              layoutAnimation: true
            },
            label: {
              normal: {
                show: true,
                position: 'inside',
                textStyle: {
                  fontSize: 16,
                  color: 'black'
                }
              }
            },
            data: genCor2Data.value,
            links: genCor2Link.value
          }
        ],
      };
      Chart.setOption(options);
    }

    var currentHEPage = ref(1)
    var HEpageSize = ref(5)
    var h2EnrichTotal = ref(0)

    const HEpagedData = computed(() => {
      const startIndex = (currentHEPage.value - 1) * HEpageSize.value;
      const endIndex = startIndex + HEpageSize.value;
      return h2_enrich_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleHECurrentChange = (newPage) => {
      currentHEPage.value = newPage;
    };
    // 处理每页条数改变事件
    const handleHESizeChange = (newSize) => {
      HEpageSize.value = newSize;
    };

    const sortLDAKChange = (arg) => {
      let sortData = h2_enrich_table_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'COMPONENT'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'COMPONENT'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      h2_enrich_table_data.value = sortData
      currentHEPage.value = 1
    }

    const sortGCChange = (arg) => {
      let sortData = h2_filter_genecor_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'STUDY2' || arg.prop === 'TRAIT2'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'STUDY2' || arg.prop === 'TRAIT2'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      h2_filter_genecor_data.value = sortData
      currentHGCPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    var currentHGCPage = ref(1)
    var HGCpageSize = ref(5)
    var h2GCTotal = ref(0)

    const HGCpagedData = computed(() => {
      const startIndex = (currentHGCPage.value - 1) * HGCpageSize.value;
      const endIndex = startIndex + HGCpageSize.value;
      return h2_filter_genecor_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleHGCCurrentChange = (newPage) => {
      currentHGCPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleHGCSizeChange = (newSize) => {
      HGCpageSize.value = newSize;
    };

    const fetchH2Data = async () => {
      try {
        const response = await proxy.$request.get('/heritability',{ 
          params:{
            study:route.params.study
        }})
        h2_table_data.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchH2EnrichData = async () => {
      try {
        const response = await proxy.$request.get('/h2_enrich',{ 
          params:{
            study:route.params.study
        }})
        h2_enrich_table_data.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const fetchH2GenCorData = async () => {
      try {
        const response = await proxy.$request.get('/h2_GenCor',{ 
          params:{
            study:route.params.study
        }})
        h2_gencor_table_data.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    const writeOption = () => {
      let studySet = new Set(h2_gencor_table_data.value.map(item => item.TRAIT2))
      study_options.value = Array.from(studySet).map(item => ({
        label:item,
        value:item
      }))
    }

    var filterByTraitOrAuthor = () => {
      h2_filter_genecor_data.value = h2_gencor_table_data.value.filter(
        (data) => {
          return (!study2.value || data.TRAIT2 == study2.value) &&
          (!LDAK_RG.value || (data.LDAK_GC > LDAK_RG.value && data.LDAK_GC != "NA")) &&
          (!LDAK_P.value || (data.LDAK_GC_P < LDAK_P.value && data.LDAK_GC_P != "NA")) &&
          (!LDSC_RG.value || (data.LDSC_GC > LDSC_RG.value && data.LDSC_GC != "NA")) &&
          (!LDSC_P.value || (data.LDSC_GC_P < LDSC_P.value && data.LDSC_GC_P != "NA")) 
        }
      )
      h2GCTotal.value = h2_filter_genecor_data.value.length
      currentHGCPage.value = 1
    }

    var reset = () => {
      study2.value = ''
      h2_filter_genecor_data.value = h2_gencor_table_data.value
      h2GCTotal.value = h2_filter_genecor_data.value.length
      currentHGCPage.value = 1
    }

    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    watch(h2_table_data, (newValue, oldValue) => {
      h2ChartInit();
    })

    watch(h2_enrich_table_data, (newValue, oldValue) => {
      h2EnrichTotal.value = h2_enrich_table_data.value.length
      Trait_list.value = [route.params.study]
      HEdata.value = h2_enrich_table_data.value.map((item,index) => {
        region.value.push(item.COMPONENT)
        return (
          [index, 0, item.ENRICHMENT, item.ENRICHMENT_P, -1.0*(Math.log10(item.ENRICHMENT_P))]
        )
      })
      h2EnrichChartInit();
    })

    watch(h2_gencor_table_data, (newValue, oldValue) => {
      h2GCTotal.value = h2_gencor_table_data.value.length
      filterByTraitOrAuthor()
      writeOption()
    })

    watch(HGCpagedData, (newValue, oldValue) => {
      var trait1 = ''
      var tmp = HGCpagedData.value.filter(
        (data) => {
          return data.LDAK_GC != 'NA'
        }
      )
      genCor1Data.value = tmp.map((item) => {
        return {
          name:item.STUDY2,
          symbolSize: [80, 80],
          value:item.TRAIT2
        }
      })
      if (HGCpagedData.value.length != 0){
        trait1 = HGCpagedData.value[0].TRAIT1
      }
      genCor1Data.value.push({
        name:route.params.study,
        x: 200,
        y: 200,
        symbolSize: [80, 80],
        value: trait1
      })
      genCor1Link.value = HGCpagedData.value.map((item) => {
        return {
          target: item.STUDY2,
          source: item.STUDY1,
          value:[item.LDAK_GC, item.LDAK_GC_P],
          lineStyle:{
            width: Math.min(Math.sqrt(Math.abs(item.LDAK_GC))*10, 80),
            color: item.LDAK_GC > 0 ? 'red' : 'blue'
          }
        }
      })
      geneCorChart1Init();

      tmp = HGCpagedData.value.filter(
        (data) => {
          return data.LDSC_GC != 'NA'
        }
      )

      genCor2Data.value = tmp.map((item) => {
        return {
          name:item.STUDY2,
          symbolSize: [80, 80],
          value:item.TRAIT2
        }
      })
      if (HGCpagedData.value.length != 0){
        trait1 = HGCpagedData.value[0].TRAIT1
      }
      genCor2Data.value.push({
        name:route.params.study,
        x: 200,
        y: 200,
        symbolSize: [80, 80],
        value: trait1
      })
      
      genCor2Link.value = HGCpagedData.value.map((item) => {
        return {
          target: item.STUDY2,
          source: item.STUDY1,
          value:[item.LDSC_GC, item.LDSC_GC_P],
          lineStyle:{
            width: Math.min(Math.sqrt(Math.abs(item.LDSC_GC))*10, 80),
            color: item.LDSC_GC > 0 ? 'red' : 'blue'
          }
        }
      })
      geneCorChart2Init();
    })

    onMounted(() => {
      fetchH2Data();
      fetchH2EnrichData();
      fetchH2GenCorData();
    })
    
    return{
      h2Chart,
      h2EnrichChart,
      geneCorChart1,
      geneCorChart2,
      trait,
      h2_table_data,
      h2_enrich_table_data,
      currentHEPage,
      currentHGCPage,
      HEpageSize,
      HGCpageSize,
      h2EnrichTotal,
      h2GCTotal,
      HEpagedData,
      HGCpagedData,
      study_options,
      study2,
      LDAK_RG,
      LDAK_P,
      LDSC_RG,
      LDSC_P,
      handleHECurrentChange,
      handleHGCCurrentChange,
      handleHESizeChange,
      handleHGCSizeChange,
      filterByTraitOrAuthor,
      reset,
      sortLDAKChange,
      sortGCChange,
      NAFormat
    }
  }
}
</script>

<style scoped>
  .equal-width-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%
  }
  .bar{
    width: 100%;
    height: 600px;
  }
  .geneCor{
    display: flex
  }
</style>