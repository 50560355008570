<template>
  <div class="bottom-line">
  </div>
  <div class="bottom-bar">
  </div>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

<style>
.bottom-line{
  background-color: rgb(88, 113, 255);
  height: 3px;
}
.bottom-bar {
  background-color: #f5f7fa;
  padding: 20px 0;
  text-align: center;
}
</style>