<template>
  <div class="container">
    <h2 class="title">
      <span style="color:#a82d26">C</span>ardio<span style="color:#335495">G</span>ene: a comprehensive platform for cardiovascular diseases and related phenotypes
    </h2>
    <el-divider />
    <div class="search">
      <el-select
        v-model="selected_type"
        filterable
        size="large"
        style="width: 100px">
        <el-option
          v-for="item in select_type"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div class="search_box">
        <el-input
          v-model="Trait"
          style="width: 100%"
          placeholder="Please input 1 Trait/SNP/Gene (e.g. Coronary Artery Disease/rs3918286/APOE)"
          @keyup.enter = "search"
          clearable
          size="large"
        />
        <!-- <el-autocomplete
          type="text"
          placeholder="Enter Traits, Example: heart failure"
          :fetch-suggestions="querySearch"
          clearable
          v-model="Trait"
          size="large"
          @keyup.enter="search"
        /> -->
      </div>
      <button class="search_btn" @click="search">
        <SearchOutlined class="search_icon"/>
      </button>
    </div>
    <!-- <div style="display: flex;justify-content: center;">
      <div style="width: 52%;">
        <div class="radio_select">
          <div>Search by:</div>
          <el-radio-group style="margin-left: 20px;" v-model="searchKey">
            <el-radio :value="'Gene'">Gene</el-radio>
            <el-radio :value="'SNP'">SNP</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div> -->
    <!-- <el-divider /> -->
    <div class="statistics">
      <div class="stat_item" @click="searchNone">
        <el-statistic class="stat_num"
         value-style="font-size: 42px;font-weight: bolder;color: #0c64b6;" 
         :value="statistic_snp"
        />
        <div class="stat_name">SNP</div>
      </div>
      <div class="stat_item" @click="searchNone">
        <el-statistic class="stat_num"
         value-style="font-size: 42px;font-weight: bolder;color: #0c64b6;"
         :value="statistic_gene"
        />
        <div class="stat_name">Gene</div>
      </div>
      <div class="stat_item" @click="searchNone">
        <div></div>
        <el-statistic class="stat_num"
         value-style="font-size: 42px;font-weight: bolder;color: #0c64b6;" 
         :value="statistic_trait"
        />
        <div class="stat_name">Trait</div>
      </div>
      <div class="stat_item" @click="searchNone">
        <div></div>
        <el-statistic class="stat_num"
         value-style="font-size: 42px;font-weight: bolder;color: #0c64b6;" 
         :value="statistic_cell"
        />
        <div class="stat_name">Cell/Tissue</div>
      </div>
    </div>
    <div class="box">
      <div class="left_container">
        <div class="box_border">
          <div class="intro">
            About CardioGene Database
          </div>
          <div class="about">
            <ul>
              <li>Cardiovascular diseases are a major cause of death globally, estimated to claim 17.9 million lives each year, significantly impacting human health, quality of life, and healthcare costs. Understanding the complex genetic mechanisms of cardiovascular diseases is crucial for developing targeted therapies, improving prevention strategies, and ultimately reducing the global burden of these life-threatening conditions.</li>
              <li>CardioGene is a platform dedicated to understanding the genetic basis of cardiovascular related traits. Despite the widespread use of genetic tools in studying complex diseases, significant challenges remain in interpreting these mechanisms. Many loci identified through GWAS are located in non-coding regions, complicating their interpretation. Non-coding regulatory elements such as transcription factors, alternative splicing, methylation, and acetylation can influence TWAS results, making it difficult to identify causal genes. CardioGene addresses these challenges by offering comprehensive genetic analysis through multiple methods to identify potential causal genes, variants, and relevant tissues or cell types for cardiovascular related traits.</li>
              <li>The platform integrates 1518 GWAS summary statistics, covering a variety of conditions and indicators, including: Diseases: Such as coronary artery disease, atherosclerosis. Biochemical Indicators: Such as lipoprotein cholesterol levels, apolipoprotein levels. Physiological Measurements: Such as pulse rate, systolic blood pressure. Drugs: Such as pravastatin, nicorandil. Through its extensive database and thorough analysis tools, CardioGene aims to provide deeper insights into the genetic factors underlying cardiovascular related traits.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right_container">
        <div class="top_container">
          <div class="intro">
            Statistics
          </div>
          <div class="statistics1">
            <div class="stat1">
              <img src="../assets/gene-9.png">
              <div>30,591 Genes</div>
            </div>
            <div class="stat1">
              <img src="../assets/cells-2.png">
              <div>190 Cell Types</div>
            </div>
            <div class="stat1">
              <img src="../assets/variant.png">
              <div>89,497,482 Trait Variants</div>
            </div>
          </div>
        </div>
        <div class="bottom_container">
          <div class="intro">
            Release Note
          </div>
          <div class="release">
            <el-timeline style="margin-top: 30px;">
              <el-timeline-item style="font-size: 20px"
                v-for="(activity, index) in activities"
                :key="index"
                type="primary"
                hollow="true"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="intro">
      Components of CardioGene
    </div>
    <div class="about">
      <ul>
        <li>
          MAGMA is used for gene analysis and generalized gene-set analysis of GWAS summary statistics.
        </li>
        <li>
          LDAK and LDSC compute SNP-based heritability and genetic correlations between phenotypes, respectively. LDAK additionally estimates unique and total heritability across 66 components.
        </li>
        <li>
          Gene-based TWAS association by S-PrediXcan, UTMOST and JTI.
        </li>
        <li>
          GARFIELD integrates GWAS summary statistics with regulatory or functional annotations.
        </li>
        <li>
          Finemapping analysis by ABF, FINEMAP and SuSiE.
        </li>
        <li>
          Analysis between two Kinds of xQTLs and cardiovascular diseases and related phenotypes by SMR and COLOC.
        </li>
      </ul>
    </div> -->
    <div style="margin-bottom: 18px;">
      <div class="box_border">
        <div class="intro">
          How to Cite
        </div>
        <div class="about" style="padding-bottom: 10px;">
          CardioGene: a comprehensive platform for cardiovascular diseases and related phenotypes
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, getCurrentInstance } from 'vue'
import { ElInput, ElSelect, ElOption, ElDivider, ElAutocomplete, ElRadioGroup, ElRadio, ElStatistic, ElTimeline, ElTimelineItem } from 'element-plus';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons-vue'
import { useTransition } from '@vueuse/core'
import { useRouter } from 'vue-router';

export default {
  name:'Home',
  components:{
    CloseCircleOutlined,
    SearchOutlined,
    ElDivider,
    ElRadioGroup,
    ElRadio,
    ElAutocomplete,
    ElStatistic,
    ElTimeline,
    ElTimelineItem,
    ElSelect,
    ElOption,
    ElInput
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    var Trait = ref('')
    var traitArray = ref([])

    const snp = ref(0)
    const statistic_snp = useTransition(snp, {
      duration: 1500,
    })
    snp.value = 89497482

    const gene = ref(0)
    const statistic_gene = useTransition(gene, {
      duration: 1500,
    })
    gene.value = 30591

    const trait = ref(0)
    const statistic_trait = useTransition(trait, {
      duration: 1500,
    })
    trait.value = 193

    const cell = ref(0)
    const statistic_cell = useTransition(cell, {
      duration: 1500,
    })
    cell.value = 190

    var selected_type = ref('Trait')

    const select_type = [
      {
        value:'Trait',
        label:'Trait'
      },
      {
        value:'SNP',
        label:'SNP'
      },
      {
        value:'Gene',
        label:'Gene'
      }
    ]

    const activities = [
      {
        content:'Bug fixed.',
        timestamp:'2024-08-06'
      },
      {
        content:'CardioGene version 1.0 is released.',
        timestamp:'2024-06-30'
      }
    ]

    function cleanSearch(){
      Trait.value = ''
    }
    const search = () => {
      if (Trait.value == ''){
        alert('searchWord can not be null!')
        return
      }
      if(selected_type.value == 'Trait'){
        router.push({
          path:'/Overview',
          query: {
            Trait: Trait.value,
          }
        })
      }else if(selected_type.value == 'Gene'){
        router.push({
          path:'/Search',
          query: {
            Gene: Trait.value
          }
        })
      }else if(selected_type.value == 'SNP'){
        router.push({
          path:'/Search',
          query: {
            Variant: Trait.value
          }
        })
      }
    }
    const searchNone = () => {
      router.push({
        path:'/Overview'
      })
    }

    const querySearch = (queryString, cb) => {
      const results = queryString
      ? traitArray.value.filter(createFilter(queryString))
      : traitArray.value
      cb(results)
    }

    const createFilter = (queryString) => {
      return (traitArray) => {
        return (
          traitArray.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    }

    const fetchetaritsData = async () => {
      try {
        const response = await proxy.$request.get('/overview')
        let traitSet = new Set(response.data.map(item => item.MeshTerm))
        traitSet.delete("NA")
        traitArray.value = Array.from(traitSet).sort().map((item) => {
          return {
            value:item,
            link:item
          }
        })
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    onMounted(()=>{
      fetchetaritsData()
    })

    return {
      Trait,
      statistic_snp,
      statistic_gene,
      statistic_trait,
      statistic_cell,
      activities,
      select_type,
      selected_type,

      cleanSearch,
      search,
      searchNone,
      querySearch
    }
  }
}
</script>

<style scoped>
  .container{
    padding: 0,100px,0,100px;
  }
  .title{
    text-align: center;
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-weight: 600;
    font-size: 30px;
    background-color:rgba(240, 242, 245, 1);
    min-height: 70px;
    line-height: 70px;
    margin: 0;
  }
  .sep{
    border-top: 1px solid rgba(0, 0, 0, .06);
    margin: 24px 0;
  }
  .search{
    display: flex;
    justify-content: center;
    text-align: center;
    align-content: center;
  }
  .search_box{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 50%;
  }
  .radio_select{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .intro{
    text-align: center;
    font-size: 26px;
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-weight: bold;
    margin: 20px 0;
  }
  .about{
    font-size: 20px;
    line-height: 1.7;
    text-align: justify;
  }
  input{
    width:100%;
    height: 25px;
    font-size: 16px;
    border: none;
    outline:none
  }
  input:focus{
    outline:none
  }
  input::placeholder{
    color: #d0d0d0;
  }
  .clean_icon{
    color:#b2b2b2
  }
  .clean_icon:hover{
    color:#606060
  }
  .search_icon{
    color: white;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
  .search_btn{
    border: 1px solid #0c64b6;
    border-radius: 0 2px 2px 0;
    background-color: #0c64b6;
    transition: border 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
    width: 50px;
  }
  .search_btn:hover{
    border: 1px solid #127fe5;
    background-color: #127fe5;
    cursor: pointer;
  }
  .statistics{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: 0 400px;
  }
  .statistics1{
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 20px;
    margin-top: 50px;
  }
  .stat_item{
    /* flex-grow: 1; */
    margin-top:60px;
    margin-bottom: 60px;
    width: 300px;
    /* transition: border 0.3s ease-in-out; */
  }
  .stat_item:hover{
    /* transition: border 0.3s ease-in-out;
    border: 1px solid #5f5f5f;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px; */
    cursor:pointer
  }
  .stat_num{
    font-size: 42px;
    font-weight: bolder;
    color: #0c64b6;
  }
  .stat_name{
    font-size: 25px;
    font-weight: bold;
    color: rgb(111, 108, 127);
    width: 300px;
  }
  .stat1{
    flex: 1;
  }
  .box{
    display: flex;
    align-items: stretch;
    margin-bottom: 18px;
  }
  .left_container{
    width: 50%;
    flex: 1;
    margin-right: 8px;
  }
  .right_container{
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  .box_border, .top_container, .bottom_container{
    border: 5px solid rgb(226, 226, 226);
    padding: 0 30px 0 20px;
  }
  .top_container, .bottom_container{
    flex: 1;
    height: 50%;
  }
  .top_container{
    margin-bottom: 6px;
    position: relative;
  }
  .bottom_container{
    margin-top: 6px;
  }
  img{
    width: 110px;
  }
  :deep(.el-input__wrapper){
    border-radius: 0;
  }
  :deep(.el-select__wrapper){
    border-radius: 5px 0 0 5px;
  }
</style>