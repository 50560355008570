<template>
  <h2 class="title">
    The Fine mapping results for {{ trait }}
  </h2>
  <div class="table_search">
    <div class="table_select">
      <h2 >Select a locus to view the corresponding results:</h2>
      <el-select-v2
        v-model="locus"
        placeholder="input and select a locus"
        filterable
        :options="locus_options"
        style="width: 240px;margin-left: 10px"
      />
    </div>
  </div>
  <Finemap :study="trait" :locus="locus"></Finemap>
  <el-backtop :right="50" :bottom="50" />
</template>

<script>
import { reactive, ref, watch, onMounted, computed, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
import { ElSelectV2, ElBacktop } from 'element-plus';
import Finemap from '../components/Finemap.vue'


export default {
  name:'FineMapping',
  components:{
    ElSelectV2,
    Finemap,
    ElBacktop
  },
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const trait = ref(route.params.study)
    var locus = ref('')
    var locus_options = ref('')

    const fetchData = async () => {
      try {
        const response = await proxy.$request.get('/finemap_gene',{ 
          params:{
            study:route.params.study
        }})
        locus_options.value = response.data.map((item) => {
          return {
            value:item,
            label:item
          }
        })
        locus.value = response.data[0]
      } catch (error) {
        console.log('fail', error.message)
      }
    }

    onMounted(() => {
      fetchData()
    })

    return {
      trait,
      locus,
      locus_options
    }
  }
}
</script>

<style>

</style>