<template>
  <h2 style="text-align: center;font-size: 32px;">The TWAS results for {{ trait }}</h2>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">Tissue:</div>
      <div>
        <el-select-v2
          v-model="tissue"
          placeholder="input and select a tissue"
          filterable
          :options="tissue_options"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="table_select">
      <div class="search_title">Gene:</div>
      <div>
        <el-select-v2
          v-model="gene"
          placeholder="input and select a gene"
          filterable
          :options="gene_options"
          style="width: 240px"
        />
      </div>
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filter">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loading"
        :data="pagedData"
        :border="true"
        style="width: 100%; overflow-y: auto"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="Study" label="Study" min-width="125" />
        <el-table-column prop="Trait" label="Trait" min-width="125" />
        <el-table-column prop="Tissue" label="Tissue" min-width="125" />
        <el-table-column sortable prop="gene" label="Gene" min-width="125">
          <template v-slot="scope">
            <a v-if="scope.row.gene" class="pmid" :href="'https://www.genecards.org/Search/Keyword?queryString='+scope.row.gene" target="_blank">{{scope.row.gene}}</a>
          </template>
        </el-table-column>
        <el-table-column sortable prop="UTMOST_BETA" label="UTMOST Beta" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="UTMOST_Z" label="UTMOST Z" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="UTMOST_P" label="UTMOST P" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="UTMOST_FDR" label="UTMOST FDR" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="JTI_BETA" label="JTI Beta" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="JTI_Z" label="JTI Z" min-width="125" :formatter="NAFormat" /> 
        <el-table-column sortable prop="JTI_P" label="JTI P" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="JTI_FDR" label="JTI FDR" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="PrediXcan_BETA" label="PrediXcan eQTL Beta" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="PrediXcan_Z" label="PrediXcan eQTL Z" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="PrediXcan_P" label="PrediXcan eQTL P" min-width="125" :formatter="NAFormat" />     
        <el-table-column sortable prop="PrediXcan_FDR" label="PrediXcan eQTL FDR" min-width="125" :formatter="NAFormat" />     
      </el-table>

      <el-pagination
        style="float: right;margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :total="Total"
        :page-sizes="[5,10,20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" 
      /> 
    </div>
  </div>
</template>

<script>
import { ElSelectV2, ElOption, ElTable, ElTableColumn, ElButton, ElDivider, ElInput, ElPagination } from 'element-plus';
import { ref,onMounted,computed, watch, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router';
export default {
  components: {
    ElSelectV2, 
    ElOption, 
    ElTable, 
    ElTableColumn, 
    ElButton, 
    ElDivider,
    ElInput,
    ElPagination
  },
  name:'TWAS',
  setup(){
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    var loading = ref(false)
    var trait = ref(route.params.study)
    var table_data = ref([])
    var filter_table_data = ref([])
    var tissue = ref('')
    var tissue_options = ref([])
    var gene = ref('')
    var gene_options = ref([])

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    const pagedData = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize.value;
      const endIndex = startIndex + pageSize.value;
      return filter_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    const sortChange = (arg) => {
      let sortData = filter_table_data.value
      if (arg.order === 'descending') {
        if (arg.prop === 'gene'){
          sortData.sort(sortByName(arg.prop, true))
        }
        else{
          sortData.sort(sortByProp(arg.prop, true))
        }
      }else{
        if (arg.prop === 'gene'){
          sortData.sort(sortByName(arg.prop, false))
        }
        else{
          sortData.sort(sortByProp(arg.prop, false))
        }
      }
      filter_table_data.value = sortData
      currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    const filter = () => {
      filter_table_data.value = table_data.value.filter(
        (data) => {
          return (!gene.value || data.gene == gene.value)  &&
          (!tissue.value || data.Tissue == tissue.value)
        }
      )
      Total.value = filter_table_data.value.length
      currentPage.value = 1
    }

    const reset = () => {
      gene.value = ''
      tissue.value = ''
      filter_table_data.value = table_data.value
      Total.value = filter_table_data.value.length
      currentPage.value = 1
    }

    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    watch(table_data, (newValue, oldValue) => {
      filter()
      let tissueSet =new Set()
      let geneSet =new Set()
      for (let i = 0; i< table_data.value.length;i++){
        tissueSet.add(table_data.value[i].Tissue)
        geneSet.add(table_data.value[i].gene)
      }
      tissue_options.value = Array.from(tissueSet).map((item)=>{
        return {
          value:item,
          label:item
        }
      })
      gene_options.value = Array.from(geneSet).map((item)=>{
        return {
          value:item,
          label:item
        }
      })
    })

    const fetchtabledata = async () => {
      loading.value = true
      try {
        const response = await proxy.$request.get('/TWAS',{ 
          params:{
            study:route.params.study
        }})
        table_data.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
      loading.value = false
    }

    onMounted(() => {
      fetchtabledata()
    })

    return {
      trait,
      tissue,
      tissue_options,
      gene,
      gene_options,
      currentPage,
      pageSize,
      Total,
      pagedData,
      loading,

      filter,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      NAFormat
    }
  }
}
</script>

<style>
  .pmid{
    text-decoration: none;
    color: rgb(16, 16, 206);
  }
</style>