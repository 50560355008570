<template>
  <h2 class="title">
    The GARFIELD results for {{ trait }}
  </h2>
  <el-tabs v-loading="loadingimg" type="card">
    <el-tab-pane label="Hotspots">
      <img :src="Hotspots" alt="Hotspots" style="width:60%">
    </el-tab-pane>
    <el-tab-pane label="Peaks">
      <img :src="Peaks" alt="Peaks" style="width:60%">      
    </el-tab-pane>
    <el-tab-pane label="Chromatin_States">
      <img :src="Chromatin_States" alt="Chromatin_States" style="width:60%">    
    </el-tab-pane>
    <el-tab-pane label="FAIRE">
      <img :src="FAIRE" alt="FAIRE" style="width:60%">
    </el-tab-pane>
    <el-tab-pane label="Footprints">
      <img :src="Footprints" alt="Footprints" style="width:60%">
    </el-tab-pane>
    <el-tab-pane label="Genic">
      <img :src="Genic" alt="Genic" style="width:60%">
    </el-tab-pane>
    <el-tab-pane label="Histone_Modifications">
      <img :src="Histone_Modifications" alt="Histone_Modifications" style="width:60%">
    </el-tab-pane>
    <el-tab-pane label="TFBS">
      <img :src="TFBS" alt="TFBS" style="width:60%">
    </el-tab-pane>
  </el-tabs>
  <el-divider />
  <div class="table_search">
    <div class="table_select">
      <div class="search_title">Cell Type:</div>
      <div>
        <el-select
          v-model="cell_type"
          placeholder="input and select a cell type"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in cell_type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="table_select">
      <div class="search_title">Tissue:</div>
      <div>
        <el-select
          v-model="tissue"
          placeholder="input and select a tissue"
          filterable
          style="width: 240px">
          <el-option
            v-for="item in tissue_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="table_select">
      <div class="search_title">OR  ></div>
      <el-input v-model="or" style="width: 240px" type="number" placeholder="input OR" />
    </div>
    <div class="table_select">
      <div class="search_title">P {{ '<' }}</div>
      <el-input v-model="p" style="width: 240px" type="number" placeholder="input P" />
    </div>
    <div class="search_button">
      <el-button type="primary" @click="filterByTraitOrAuthor">Search</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
  </div>
  <div>
    <div class="table_container">
      <el-table 
        v-loading="loadingtable"
        :data="pagedData"
        :border="true"
        style="width: 100%; overflow-y: auto"
        :header-cell-style="{backgroundColor:'#F6F6F6'}"
        @sort-change="sortChange"
      >
        <el-table-column prop="Study" label="Study" min-width="125" />
        <el-table-column prop="Trait" label="Trait" min-width="125" />
        <el-table-column sortable prop="PThresh" label="P Threshold" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="OR" label="OR" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="Pvalue" label="P" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="Beta" label="Beta" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="SE" label="SE" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="CI95_lower" label="95% CI Lower" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="CI95_upper" label="95% CI Upper" min-width="125" :formatter="NAFormat" /> 
        <el-table-column sortable prop="NAnnotThesh" label="NAnnotThesh" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="NAnnot" label="NAnnot" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="NThresh" label="NThresh" min-width="125" :formatter="NAFormat" />
        <el-table-column sortable prop="N" label="#Sample" min-width="125" :formatter="NAFormat" />
        <el-table-column prop="Celltype" label="CellType" min-width="125" />
        <el-table-column prop="Tissue" label="Tissue" min-width="125" />
        <el-table-column prop="Type" label="Type" min-width="125" />        
      </el-table>

      <el-pagination
          style="float: right;margin-top: 10px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="Total"
          :page-sizes="[5,10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" 
        /> 
    </div>
  </div>

</template>

<script>
import { ElTabs, ElTabPane, ElSelect, ElOption, ElTable, ElTableColumn, ElButton, ElInput, ElDivider, ElPagination } from 'element-plus';
import { reactive, ref, computed, watch, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name:'GARFIELD',
  components:{
    ElTabs,
    ElTabPane,
    ElSelect,
    ElOption,
    ElTable,
    ElTableColumn,
    ElButton,
    ElInput,
    ElDivider,
    ElPagination
  },
  setup(){
    const { proxy } = getCurrentInstance()
    var loadingimg = ref(false)
    var loadingtable = ref(false)
    var route = useRoute()
    var trait = ref(route.params.study)
    var Hotspots = ref('')
    var Peaks = ref('')
    var Chromatin_States = ref('')
    var FAIRE = ref('')
    var Footprints = ref('')
    var Genic = ref('')
    var Histone_Modifications = ref('')
    var TFBS = ref('')

    var or = ref('')
    var p = ref('')
    var cell_type = ref('')
    var tissue = ref('')
    var cell_type_options = ref([])
    var tissue_options = ref([])

    var table_data = ref([])

    var filter_table_data = ref(table_data.value)

    var filterByTraitOrAuthor = () => {
      filter_table_data.value = table_data.value.filter(
        (data) => {
          return (!cell_type.value || data.Celltype == cell_type.value) 
          && (!tissue.value || data.Tissue == tissue.value)
          && (!or.value || data.OR > or.value)
          && (!p.value || data.Pvalue < p.value)
        }
      )
      Total.value = filter_table_data.value.length
      currentPage.value = 1
    }

    var reset = () => {
      cell_type.value = ''
      tissue.value = ''
      or.value = ''
      p.value = ''
      filter_table_data.value = table_data.value
      Total.value = filter_table_data.value.length
      currentPage.value = 1
    }

    const sortChange = (arg) => {
      let sortData = filter_table_data.value
      if (arg.order === 'descending') {
          sortData.sort(sortByProp(arg.prop, true))
      }else{
          sortData.sort(sortByProp(arg.prop, false))
      }
      filter_table_data.value = sortData
      currentPage.value = 1
    }

    const sortByProp = (sortKey, order) => {
      if (order){
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return bb - aa
        }
      }else{
        return (a,b) => {
          let aa = a[sortKey] == "NA" ? Infinity : a[sortKey]
          let bb = b[sortKey] == "NA" ? Infinity : b[sortKey]
          return aa - bb
        }
      }
    }
    const sortByName = (sortKey, order) => {
      if (order) {
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return b[sortKey].localeCompare(a[sortKey])
        }
      }else{
        return (a,b) => {
          a[sortKey] = a[sortKey] == null ? '' : a[sortKey].toString()
          b[sortKey] = b[sortKey] == null ? '' : b[sortKey].toString()
          return a[sortKey].localeCompare(b[sortKey])
        }
      }
    }

    var currentPage = ref(1)
    var pageSize = ref(5)
    var Total = ref(0)

    const pagedData = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize.value;
      const endIndex = startIndex + pageSize.value;
      return filter_table_data.value.slice(startIndex, endIndex);
    });
    // 处理页码改变事件
    const handleCurrentChange = (newPage) => {
      currentPage.value = newPage;
    };

    // 处理每页条数改变事件
    const handleSizeChange = (newSize) => {
      pageSize.value = newSize;
      currentPage.value = 1
    };

    const fetchtabledata = async () => {
      loadingtable.value = true
      try {
        const response = await proxy.$request.get('/garfield',{ 
          params:{
            study:route.params.study
        }})
        table_data.value = response.data
      } catch (error) {
        console.log('fail', error.message)
      }
      loadingtable.value = false
    }

    const fetchPNGdata = async () => {
      loadingimg.value = true
      try {
        const response = await proxy.$request.get('/garfieldPNG',{ 
          params:{
            study:route.params.study
        }})
        Hotspots.value = response.data.Hotspots
        Peaks.value = response.data.Peaks
        Chromatin_States.value = response.data.Chromatin_States
        FAIRE.value = response.data.FAIRE
        Footprints.value = response.data.Footprints
        Genic.value = response.data.Genic
        Histone_Modifications.value = response.data.Histone_Modifications
        TFBS.value = response.data.TFBS
      } catch (error) {
        console.log('fail', error.message)
      }
      loadingimg.value = false
    }

    watch(table_data,(newValue,oldValue) => {
      let cellTypeSet = new Set()
      let tissueSet = new Set()
      for (let i = 0; i < table_data.value.length; i++){
        cellTypeSet.add(table_data.value[i].Celltype)
        tissueSet.add(table_data.value[i].Tissue)
      }
      cell_type_options.value = Array.from(cellTypeSet).sort().map((item) => {
        return {
          value:item,
          label:item
        }
      })
      tissueSet.delete("NA")
      tissue_options.value = Array.from(tissueSet).sort().map((item) => {
        return {
          value:item,
          label:item
        }
      })
      filterByTraitOrAuthor()
    })

    const NAFormat = (row,column,value) => {
      let tmp = null
      tmp = value == ("NA" || Infinity) ? '-' : (value < 0.0001 && value >= -0.0001 && value != 0) ? value.toExponential(4) : value
      return tmp
    }

    onMounted(() => {
      fetchPNGdata()
      fetchtabledata()
    })

    return{
      loadingtable,
      loadingimg,
      trait,
      Hotspots,
      Peaks,
      Chromatin_States,
      FAIRE,
      Footprints,
      Genic,
      Histone_Modifications,
      TFBS,
      or,
      p,
      cell_type,
      tissue,
      cell_type_options,
      tissue_options,
      currentPage,
      pageSize,
      Total,
      pagedData,
      filterByTraitOrAuthor,
      reset,
      handleCurrentChange,
      handleSizeChange,
      sortChange,
      NAFormat
    }
  }
}
</script>

<style>
  .title{
    font-weight: 600;
    font-size: 32px;
    line-height: 1.35;
    font-family: "Trebuchet MS", Arial, sans-serif;
    text-align: center;
  }
  .el-tab-pane{
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
  }
  img{
    width: 75%;
  }
  .table_search{
    display: flex;
    margin-bottom: 30px;
  }
  .table_select{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 24px;
    padding-right: 25px;
  }
  .search_title{
    margin-right: 8px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  .table_container{
    padding: 0 24px 24px 24px;
    overflow-x: auto;
  }
</style>