import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import { ElLoading } from 'element-plus'
import request from './axios/axios.js'

const app = createApp(App)
app.config.globalProperties.$request = request
app.directive('loading',ElLoading.directive)
app.use(router)
app.mount('#app')

// const debounce = (fn, delay) => {
//   let timer
//    return (...args) => {
//      if (timer) {
//        clearTimeout(timer)
//      }
//      timer = setTimeout(() => {
//        fn(...args)
//      }, delay)
//    }
// }
  
// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//    constructor(callback) {
//      callback = debounce(callback, 1);
//      super(callback);
//    }
// }